import React, { useState, useEffect } from "react";
import { Modal, Alert } from "reactstrap";
const axios = require("axios").default;
import { AvForm, AvField } from "availity-reactstrap-validation";
import { getCurrentUser } from "../../helpers/Utils";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";

function BrandModal(props) {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");
    const [success, setSuccess] = useState("");

    const submitBrand = (e, value) => {
        setLoading(true);
        axios
            .post(
                `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                    "companyId"
                )}/brand`,
                {
                    name: value.name,
                    description: value.description,
                },
                {
                    headers: {
                        Authorization: `Bearer ${getCurrentUser().token}`,
                    },
                }
            )
            .then(function (res) {
                setLoading(false);
                if (res.data.status) {
                    setError("");
                    setSuccess(res.data.message);
                    console.log(res.data);

                    props.setBrands(prevBrands => [
                        ...prevBrands,
                        { label: res.data.brand.name, value: res.data.brand.id }
                    ]);
                    // props.setBrands([...brands, {
                    //     label: res.data.brand.name,
                    //     value: res.data.brand.id
                    // }])
                } else {
                    setError(res.data.message);
                    setSuccess("");
                }

                setTimeout(() => {
                    props.setIsOpenModalBrand(false);
                    setError("");
                    setSuccess("");
                }, 1000);
            });
    };

    return (
        <Modal size="md" isOpen={props.isOpenModalBrand} centered={true}>
            <AvForm
                className="form-horizontal"
                onValidSubmit={(e, v) => {
                    submitBrand(e, v);
                }}
            >
                <div className="modal-header">
                    <h5 className="modal-title mt-0" id="myLargeModalLabel">
                        {props.t("New Brand")}
                    </h5>
                    <button
                        type="button"
                        className="btn btn-primary text-md btn-sm"
                        onClick={() => props.setIsOpenModalBrand(false)}
                        aria-hidden="true"
                    >
                        &times;
                    </button>
                </div>
                <div className="modal-body">
                    {success ? (
                        <div className="mb-3">
                            <Alert color="success">{props.t(success)}</Alert>
                        </div>
                    ) : null}

                    {error ? (
                        <div className="mb-3">
                            <Alert color="danger">{props.t(error)}</Alert>
                        </div>
                    ) : null}

                    <div className="mb-3 required">
                        <AvField
                            label={props.t("Name")}
                            type="text"
                            name="name"
                            required="required"
                        />
                    </div>
                    <div className="mb-3">
                        <AvField
                            label={props.t("Description")}
                            type="textarea"
                            name="description"
                        />
                    </div>
                </div>
                <div className="modal-footer">
                    <div className="text-end">
                        {loading ? (
                            <button
                                className="btn btn-primary disabled"
                                type="button"
                                disabled
                            >
                                {props.t("Loading")}
                            </button>
                        ) : (
                            <button className="btn btn-primary" type="submit">
                                {props.t('Submit')}
                            </button>
                        )}
                        <button
                            className="btn btn-primary ms-2"
                            type="button"
                            onClick={() => props.setIsOpenModalBrand(false)}
                        >
                            {props.t("Cancel")}
                        </button>
                    </div>
                </div>
            </AvForm>
        </Modal>
    );
}

export default withTranslation()(BrandModal);
BrandModal.propTypes = {
    t: PropTypes.any,
};
