import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { showRightSidebarAction, toggleLeftmenu } from "../../store/actions";
import ProfileMenu from "../CommonForBoth/TopbarDropdown/ProfileMenu";
import { withTranslation } from "react-i18next";
import logodarkImg from "../../assets/images/logo-dark.png";
import logosmImg from "../../assets/images/logo-sm.png";
import logolightImg from "../../assets/images/logo-light.png";
import {
  getCompanyLogo,
  getCurrentUser,
  setPermission,
} from "../../helpers/Utils";
const axios = require("axios").default;
import LanguageDropdown from "../CommonForBoth/TopbarDropdown/LanguageDropdown";
const Header = (props) => {
  const getAllPermissions = () => {
    axios
      .get(
        `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
          "companyId"
        )}/role-permissions`,
        {
          headers: {
            Authorization: `Bearer ${getCurrentUser().token}`,
          },
        }
      )
      .then(function (res) {
        setPermission(res.data.permissions);
      });
  };
  const getTechnical = () => {
    axios
      .get(
        `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
          "companyId"
        )}/technical-module`,
        {
          headers: {
            Authorization: `Bearer ${getCurrentUser().token}`,
          },
        }
      )
      .then(function (res) {
        if (res.data.status) {
          localStorage.setItem("tech_module", res.data.enable_technical_module);
        }
      });
  };
  const getUser = () => {
    axios
      .get(
        `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
          "companyId"
        )}/get-current-user`,

        {
          headers: {
            Authorization: `Bearer ${getCurrentUser().token}`,
          },
        }
      )
      .then(function (res) {
        if (res.data.status) {
          localStorage.setItem("plan_data", JSON.stringify(res.data.data));
        }
      });
  };
  useEffect(() => {
    if (getCurrentUser() !== null) {
      getAllPermissions();
      getTechnical();
      getUser();
      props.toggleLeftmenu(false);
    }
  }, []);

  const closeAlert = () => {
    localStorage.setItem('e_sign_expire_alert', 'close');
  }

  return (
    <React.Fragment>
      <header id="page-topbar">
        <div className="navbar-header">
          <div className="d-flex">
            <div className="navbar-brand-box">
              <Link to="/dashboard" className="logo logo-dark">
                <span className="logo-sm">
                  <img
                    src={getCompanyLogo() ? getCompanyLogo() : logosmImg}
                    alt=""
                    height="40"
                  />
                </span>
                <span className="logo-lg">
                  <img
                    src={getCompanyLogo() ? getCompanyLogo() : logodarkImg}
                    alt=""
                    height="40"
                  />
                </span>
              </Link>
              <Link to="/dashboard" className="logo logo-light">
                <span className="logo-sm">
                  <img src={logosmImg} alt="" height="40" />
                </span>
                <span className="logo-lg">
                  <img src={logolightImg} alt="" height="40" />
                </span>
              </Link>
            </div>
            <button
              type="button"
              onClick={() => {
                props.toggleLeftmenu(!props.leftMenu);
              }}
              className="btn btn-sm me-2 font-size-24 d-lg-none header-item waves-effect waves-light"
              id="vertical-menu-btn"
            >
              <i className="mdi mdi-menu"></i>
            </button>
          </div>
          <div className="d-flex">
            <LanguageDropdown />
            <ProfileMenu />
          </div>
        </div>
        {getCurrentUser().plan_expiry_days !== null &&
          getCurrentUser().plan_expiry_days <= 3 ? (
          <div
            className="d-flex justify-content-center"
            style={{ marginTop: "-10px", background: "black" }}
          >
            <span className="text-light">
              YOUR SUBSCRIPTION IS GOING TO BE EXPIRED WITHIN{" "}
              <span>{getCurrentUser().plan_expiry_days}</span> DAYS. KINDLY
              RENEW YOUR SUBSCRIPTION.
            </span>
          </div>
        ) : (
          ""
        )}

        {getCurrentUser().electronic_sig_expire_date && (() => {
          const today = new Date();

          const expireDate = new Date(getCurrentUser().electronic_sig_expire_date);

          const diffTime = expireDate.getTime() - today.getTime();
          const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
          const [showAlert, setShowAlert] = useState(localStorage.getItem('e_sign_expire_alert') !== 'close');

          const closeAlert = () => {
            localStorage.setItem('e_sign_expire_alert', 'close');
            setShowAlert(false);
          };
        
          if ((diffDays > 0 && diffDays <= 10) && showAlert) {
            return (
              <div
                className="d-flex justify-content-center"
                style={{ marginTop: "-10px", background: "black", position: "relative" }}
              >
                <span className="text-light">
                  YOUR ELECTRONIC SIGNATURE IS GOING TO BE EXPIRED WITHIN{" "}
                  <span>{diffDays}</span> DAYS. KINDLY RENEW YOUR SIGNATURE.
                </span>
                <span style={{ right: '10px', position: 'absolute', cursor: 'pointer' }} onClick={()=>closeAlert()}>x</span>
              </div>
            );
          }
          return null;
        })()}
      </header>
    </React.Fragment>
  );
};

Header.propTypes = {
  leftMenu: PropTypes.any,
  showRightSidebar: PropTypes.any,
  showRightSidebarAction: PropTypes.func,
  t: PropTypes.any,
  toggleLeftmenu: PropTypes.func,
};

const mapStatetoProps = (state) => {
  const { layoutType, showRightSidebar, leftMenu } = state.Layout;
  return { layoutType, showRightSidebar, leftMenu };
};

export default connect(mapStatetoProps, {
  showRightSidebarAction,
  toggleLeftmenu,
})(withTranslation()(Header));
