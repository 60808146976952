import React, { useState, useEffect } from "react";
import MetaTags from "react-meta-tags";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { Col, Row } from "reactstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { getCurrentUser } from "../../helpers/Utils";
import { withTranslation } from "react-i18next";
// import { Modal, Input } from "reactstrap";
const axios = require("axios").default;
import PropTypes from "prop-types";
const AssetsNew = (props) => {
  const [startWarranty, setStartWarranty] = useState(new Date());
  const [endWarranty, setEndWarranty] = useState("");
  const [referenceType, setReferenceType] = useState("ast");
  const [allBrands, setAllBrands] = useState([]);

  const getBrands = () => {
    axios
      .get(
        `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
          "companyId"
        )}/brand`,
        {
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${getCurrentUser().token}`,
          },
        }
      )
      .then(function (res) {
        setAllBrands(res.data.brands);
      });
  };

  useEffect(() => {
    getBrands();
    // RelatedAll();
  }, []);

  const AssestsCreate = (e, value) => {
    const formData = new FormData();
    formData.append("client_id", props.clientData.id);
    formData.append("reference", value.reference);
    formData.append("reference_number", value.reference_number);
    formData.append("brand_id", value.brand_id !== null ? value.brand_id : "");

    formData.append("serial_number", value.serial_number);
    formData.append("identifier", value.identifier);
    formData.append("name", value.name);
    formData.append("model", value.model);
    formData.append("address", value.address);
    formData.append("start_of_warranty", startWarranty);
    formData.append("end_of_warranty", endWarranty);
    formData.append("subject_to_maintenance", value.subject_to_maintenance);
    axios
      .post(
        `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
          "companyId"
        )}/client-assets`,
        formData,
        {
          headers: {
            "Content-type": "multipart/form-data",
            Authorization: `Bearer ${getCurrentUser().token}`,
          },
        }
      )
      .then(function () {
        props.setassetModal(false);
        props.AssetsData(props.clientData.id);
      });
  };
  const getType = (e) => {
    setReferenceType(e.target.value);
  };
  console.log("f", props.clientData)
  return (
    <React.Fragment>
      <MetaTags>
        <title> Assets New | Clouget</title>
      </MetaTags>
      <div className="tech_service">
        <AvForm
          className="form-horizontal"
          onValidSubmit={(e, v) => AssestsCreate(e, v)}
        >
          <Row>
            <Col md={12}>
              <div className="mb-3">
                <AvField
                  label={props.t("Client")}
                  type="text"
                  name="client_name"
                  // value={props.clientData.name}
                  value={props.clientNameData ? props.clientNameData : props.clientData.legal_name}
                  disabled
                />
              </div>
            </Col>
            <Col md={12}>
              <div className="mb-3">
                <AvField
                  label={props.t("Address")}
                  type="text"
                  name="address"
                  value={props.clientData.address}
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <div className="mb-3">
                <label>{props.t("Reference")}</label>
                <div className="d-flex">
                  <AvField
                    style={{
                      width: "100px",
                    }}
                    type="select"
                    name="reference"
                    value="ast"
                    onChange={(e) => getType(e)}
                  >
                    <option value="ast">AST</option>
                    <option value="manual">MANUAL</option>
                  </AvField>
                  <div className="w-100 ps-3">
                    <AvField
                      className="w-100"
                      type="number"
                      step="1"
                      min="1"
                      name="reference_number"
                      placeholder={
                        referenceType === "ast" ? `${props.t("Automatic")}` : ""
                      }
                    />
                  </div>
                </div>
              </div>
              <div className="mb-3">
                <AvField
                  type="select"
                  name="brand_id"
                  label={props.t("Brand")}
                >
                  <option></option>
                  {allBrands &&
                    allBrands.map((item, i) => (
                      <option key={i} value={item.id}>
                        {item.name}
                      </option>
                    ))}
                </AvField>
              </div>
              <div className="mb-3">
                <AvField
                  label={props.t("Serial Number")}
                  type="text"
                  name="serial_number"
                />
              </div>
              <div className="mb-3">
                <AvField label={props.t("Model")} type="text" name="model" />
              </div>
            </Col>
            <Col md={6}>
              <div className="mb-3">
                <AvField label={props.t("Asset Name")} type="text" name="name" />
              </div>
              <div className="mb-3">
                <AvField
                  label={props.t("Subject to Maintenance")}
                  type="checkbox"
                  name="subject_to_maintenance"
                />
              </div>
              <div className="mb-3">
                <label>{props.t("Start of the Warranty")}</label>
                <DatePicker
                  selected={startWarranty}
                  onChange={(e) => setStartWarranty(e)}
                  //   showTimeSelect
                  //   timeFormat="HH:mm"
                  //   timeIntervals={15}
                  //   timeCaption="time"
                  dateFormat="yyyy-MM-dd"
                  //   minDate={new Date()}
                  name="start_of_warranty"
                  className="is-untouched is-pristine av-valid form-control"
                />
              </div>
              <div className="mb-3">
                <label>{props.t("End of the Warranty")}</label>
                <DatePicker
                  selected={endWarranty}
                  onChange={(e) => setEndWarranty(e)}
                  //   showTimeSelect
                  //   timeFormat="HH:mm"
                  //   timeIntervals={15}
                  //   timeCaption="time"
                  dateFormat="yyyy-MM-dd"
                  //   minDate={new Date()}
                  name="end_of_warranty"
                  className="is-untouched is-pristine av-valid form-control"
                />
              </div>
              <div className="mb-3">
                <AvField
                  label={props.t("Identifier")}
                  type="text"
                  name="identifier"
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <div className="mb-3">
                <AvField
                  label={props.t("Comment")}
                  type="textarea"
                  name="description"
                />
              </div>
            </Col>
            <Col md={12}>
              <div className="mb-3">
                <AvField
                  label={props.t("Private Comments")}
                  type="textarea"
                  name="private_comments"
                />
              </div>
            </Col>
          </Row>
          <div className="text-end">
            <button className="btn btn-primary" type="submit">
              {props.t("Submit")}
            </button>
            <button
              className="btn btn-primary ms-2"
              type="button"
              onClick={() => props.setassetModal(false)}
            >
              {props.t("Cancel")}
            </button>
          </div>
        </AvForm>
      </div>
      {/* // </Modal> */}
    </React.Fragment>
  );
};
export default withTranslation()(AssetsNew);
AssetsNew.propTypes = {
  t: PropTypes.any,
};
