/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";
import { Collapse } from "reactstrap";
import { Link, withRouter } from "react-router-dom";
import classname from "classnames";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import {
    getCurrentUser,
    getPermission,
    setFilterDate,
} from "../../helpers/Utils";
import PropTypes from "prop-types";

const Navbar = (props) => {
    const [companyDrop, setCompanyDrop] = useState(false);
    const [catalog, setCatalog] = useState(false);
    const [client, setClient] = useState(false);
    const [sale, setSale] = useState(false);
    const [tech, setTech] = useState(false);
    const [invoice, setInvoice] = useState(false);
    const [purchase, setPurchase] = useState(false);
    const [report, setReport] = useState(false);
    const [basicInfo, setBasicInfo] = useState(false);
    const [tax, setTax] = useState(false);
    const [manage, setManage] = useState(false);
    const [product, setProduct] = useState(false);
    const [invoicing, setInvoicing] = useState(false);
    const [cashFlow, setCashFlow] = useState(false);
    const [saleReport, setSaleReport] = useState(false);
    const [techReport, setTechReport] = useState(false);
    const [purchaseReport, setPurchaseReport] = useState(false);
    const [ofevolutionReport, setOfevolutionReport] = useState(false);

    useEffect(() => {
        localStorage.setItem("reference", "");
        localStorage.setItem("clientName", "");
        localStorage.setItem("paidBy", "");
        localStorage.setItem("supplierName", "");
        localStorage.setItem("title", "");
        localStorage.setItem("createdBy", "");
        localStorage.setItem("employee", "");
        localStorage.setItem("status", "");
        localStorage.setItem("statusSo", "");
        localStorage.setItem("statusPO", "");
        localStorage.setItem("statusPDN", "");
        localStorage.setItem("statusPINV", "");
        localStorage.setItem("statusTAE", "");
        localStorage.setItem("statusSdn", "");
        localStorage.setItem("statusWI", "");
        localStorage.setItem("statusWO", "");
        localStorage.setItem("statusWDN", "");
        localStorage.setItem("statusINV", "");
        localStorage.setItem("email", "");
        localStorage.setItem("phone1", "");
        localStorage.setItem("phone", "");
        localStorage.setItem("legalName", "");
        localStorage.setItem("name", "");
        localStorage.setItem("productName", "");
        localStorage.setItem("price", "");
        localStorage.setItem("serialNumber", "");
        localStorage.setItem("amount", "");
        localStorage.setItem("amount2", "");
        localStorage.setItem("tinNumber", "");
        localStorage.setItem("statusVeronica", "");
        localStorage.setItem("is_promotional", "");
        setFilterDate({
            from: "",
            to: "",
        });
        // localStorage.setItem("leadMessage", "");
        // localStorage.setItem("followupStatus", "");
        // localStorage.setItem("deadStatus", "");
    }, []);

    useEffect(() => {
        var matchingMenuItem = null;
        var ul = document.getElementById("navigation");
        var items = ul.getElementsByTagName("a");
        for (var i = 0; i < items.length; ++i) {
            if (props.location.pathname === items[i].pathname) {
                matchingMenuItem = items[i];
                break;
            }
        }
        if (matchingMenuItem) {
            activateParentDropdown(matchingMenuItem);
        }
    });
    function activateParentDropdown(item) {
        item.classList.add("active");
        const parent = item.parentElement;
        if (parent) {
            parent.classList.add("active"); // li
            const parent2 = parent.parentElement;
            parent2.classList.add("active"); // li
            const parent3 = parent2.parentElement;
            if (parent3) {
                parent3.classList.add("active"); // li
                const parent4 = parent3.parentElement;
                if (parent4) {
                    parent4.classList.add("active"); // li
                    const parent5 = parent4.parentElement;
                    if (parent5) {
                        parent5.classList.add("active"); // li
                        const parent6 = parent5.parentElement;
                        if (parent6) {
                            parent6.classList.add("active"); // li
                        }
                    }
                }
            }
        }
        return false;
    }
    // console.log(localStorage.getItem("tech_module"), "Tech");

    return (
        <React.Fragment>
            <div className="topnav">
                <div className="container-fluid">
                    <nav
                        className="navbar navbar-light navbar-expand-lg topnav-menu"
                        id="navigation"
                    >
                        <Collapse
                            isOpen={props.leftMenu}
                            className="navbar-collapse"
                            id="topnav-menu-content"
                        >
                            <ul className="navbar-nav">
                                <li className="nav-item">
                                    <Link className="nav-link" to="/dashboard">
                                        {props.t("Home")}
                                    </Link>
                                </li>
                                {/* <li className="nav-item dropdown">
                  <Link
                    className="nav-link"
                    to={"/#"}
                    onClick={(e) => {
                      e.preventDefault();
                      setCompanyDrop(!companyDrop);
                    }}
                  >
                    {props.t("Company")}
                  </Link>
                  <div
                    className={classname("dropdown-menu", {
                      show: companyDrop,
                    })}
                  >
                    {/* <Link
                      to={`${getCurrentUser().role === 1 ? "/company" : "#"}`}
                      className="dropdown-item"
                    >
                      <span>{props.t("Company")}</span>
                    </Link> */}
                                {/* <Link
                      to={`/user/${getCurrentUser().id}`}
                      className="dropdown-item"
                    >
                      <span>{props.t("Profile")}</span>
                    </Link> */}
                                {/* {getPermission()?.users.users.view.is_checked === 1 ? (
                      <Link className="dropdown-item" to="/users">
                        {props.t("Users")}
                      </Link>
                    ) : (
                      ""
                    )}
                    {getPermission()?.roles.roles.view.is_checked === 1 ? (
                      <Link className="dropdown-item" to="/roles">
                        {props.t("Role")}
                      </Link>
                    ) : (
                      ""
                    )}
                    {getPermission()?.bank_accounts.bank_accounts.view
                      .is_checked === 1 ? (
                      <Link className="dropdown-item" to="/bank-accounts">
                        {props.t("Bank Account")}
                      </Link>
                    ) : (
                      ""
                    )}
                    {getPermission()?.my_templates.my_templates.view
                      .is_checked === 1 ? (
                      <Link className="dropdown-item" to="/templates">
                        {props.t("My Templates")}
                      </Link>
                    ) : (
                      ""
                    )}
                    {getPermission()?.custom_states.custom_states.view
                      .is_checked === 1 ? (
                      <Link className="dropdown-item" to="/custom-states">
                        {props.t("Custom State")}
                      </Link>
                    ) : (
                      ""
                    )}
                    {getPermission()?.email_configuration.email_configuration
                      .view.is_checked === 1 ? (
                      <Link className="dropdown-item" to="/email-configuration">
                        {props.t("Email Configuration")}
                      </Link>
                    ) : (
                      ""
                    )} */}
                                {/* <div className="dropdown">
                      {getPermission()?.references?.references?.view
                        .is_checked === 0 &&
                      getPermission()?.payment_terms?.payment_terms?.view
                        .is_checked === 0 &&
                      getPermission()?.price_rates?.price_rates?.view
                        .is_checked === 0 ? (
                        ""
                      ) : (
                        <Link
                          className="dropdown-item"
                          to="/#"
                          onClick={(e) => {
                            e.preventDefault();
                            setBasicInfo(!basicInfo);
                          }}
                        >
                          {props.t("Basic Configuration")}
                        </Link>
                      )}
                      <div
                        className={classname("dropdown-menu", {
                          show: basicInfo,
                        })}
                      >
                        {getPermission()?.references.references.view
                          .is_checked === 1 ? (
                          <Link className="dropdown-item" to="/references">
                            {props.t("Reference")}
                          </Link>
                        ) : (
                          ""
                        )}
                        {getPermission()?.payment_terms.payment_terms.view
                          .is_checked === 1 ? (
                          <Link className="dropdown-item" to="/payment-term">
                            {props.t("Payment Terms")}
                          </Link>
                        ) : (
                          ""
                        )}
                        {getPermission()?.price_rates.price_rates.view
                          .is_checked === 1 ? (
                          <Link className="dropdown-item" to="/rates">
                            {props.t("Price Rate")}
                          </Link>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                    <div className="dropdown">
                      {getPermission()?.taxes.taxes.view.is_checked === 1 ? (
                        <Link
                          className="dropdown-item"
                          to="/#"
                          onClick={(e) => {
                            e.preventDefault();
                            setTax(!tax);
                          }}
                        >
                          {props.t("Taxes")}
                        </Link>
                      ) : (
                        ""
                      )}
                      <div
                        className={classname("dropdown-menu", {
                          show: tax,
                        })}
                      >
                        <Link className="dropdown-item" to="/consumption-tax">
                          {props.t("Consumption")}
                        </Link>
                        <Link className="dropdown-item" to="/income-tax">
                          {props.t("Income")}
                        </Link>
                      </div>
                    </div>
                    <div className="dropdown">
                      {getPermission()?.expense_categories.expense_categories
                        .view.is_checked === 0 &&
                      getPermission()?.delivery_options.delivery_options.view
                        .is_checked === 0 &&
                      getPermission()?.payment_options.payment_options.view
                        .is_checked === 0 &&
                      getPermission()?.client_and_supplier_categories
                        .client_and_supplier_categories.view.is_checked ===
                        0 ? (
                        ""
                      ) : (
                        <Link
                          className="dropdown-item"
                          to="/#"
                          onClick={(e) => {
                            e.preventDefault();
                            setManage(!manage);
                          }}
                        >
                          {props.t("Management Listings")}
                        </Link>
                      )}
                      <div
                        className={classname("dropdown-menu", {
                          show: manage,
                        })}
                      >
                        {getPermission()?.client_and_supplier_categories
                          .client_and_supplier_categories.view.is_checked ===
                        1 ? (
                          <Link
                            className="dropdown-item"
                            to="/client-categories"
                          >
                            {props.t("Client and Supplier Categories")}
                          </Link>
                        ) : (
                          ""
                        )}
                        {getPermission()?.payment_options.payment_options.view
                          .is_checked === 1 ? (
                          <Link className="dropdown-item" to="/payment-options">
                            {props.t("All Payment Options")}
                          </Link>
                        ) : (
                          ""
                        )}
                        {getPermission()?.delivery_options.delivery_options.view
                          .is_checked === 1 ? (
                          <Link
                            className="dropdown-item"
                            to="/delivery-options"
                          >
                            {props.t("Delivery Options")}
                          </Link>
                        ) : (
                          ""
                        )}
                        {getPermission()?.expense_categories.expense_categories
                          .view.is_checked === 1 ? (
                          <Link
                            className="dropdown-item"
                            to="/expense-category"
                          >
                            {props.t("Expense Categories")}
                          </Link>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                    <Link className="dropdown-item" to="/import-export">
                      {props.t("Import/Export")}
                    </Link>
                  </div> */}
                                {/* </li> */}
                                <li className="nav-item dropdown">
                                    <Link
                                        to="/products"
                                        className="nav-link"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            setCatalog(!catalog);
                                        }}
                                    >
                                        {props.t("Catalog")}
                                    </Link>
                                    <div
                                        className={classname("dropdown-menu", { show: catalog })}
                                    >
                                        <div className="dropdown">
                                            {getPermission() &&
                                                getPermission().products.products.view.is_checked ===
                                                1 ? (
                                                <>
                                                    <Link className="dropdown-item" to="/products">
                                                        {props.t("Products")}
                                                        {getPermission() &&
                                                            getPermission().products.products.create
                                                                .is_checked === 1 ? (
                                                            <div className="arrow-down"></div>
                                                        ) : (
                                                            ""
                                                        )}
                                                    </Link>
                                                    {getPermission() &&
                                                        getPermission().products.products.create
                                                            .is_checked === 1 ? (
                                                        <div
                                                            className={classname("dropdown-menu", {
                                                                // show: product,
                                                            })}
                                                        >
                                                            <Link className="dropdown-item" to="/new-product">
                                                                {props.t("Add new")}
                                                            </Link>
                                                        </div>
                                                    ) : (
                                                        ""
                                                    )}
                                                </>
                                            ) : (
                                                ""
                                            )}
                                        </div>
                                        {getPermission()?.product_categories.product_categories.view
                                            .is_checked === 1 ? (
                                            <Link className="dropdown-item" to="/category">
                                                {props.t("Category")}
                                            </Link>
                                        ) : (
                                            ""
                                        )}
                                        {getPermission()?.services.services.view.is_checked ===
                                            1 ? (
                                            <Link className="dropdown-item" to="/services">
                                                {props.t("Services")}
                                            </Link>
                                        ) : (
                                            ""
                                        )}
                                        {getPermission()?.client_assets.client_assets.view
                                            .is_checked === 1 ? (
                                            <Link className="dropdown-item" to="/assets">
                                                {props.t("Assets")}
                                            </Link>
                                        ) : (
                                            ""
                                        )}
                                    </div>
                                </li>
                                <li className="nav-item dropdown">
                                    <Link
                                        className="nav-link"
                                        to="/clients"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            setClient(!client);
                                        }}
                                    >
                                        {props.t("Clients")}
                                    </Link>
                                    <div className={classname("dropdown-menu", { show: client })}>
                                        <div className="dropdown">
                                            {getPermission()?.clients.clients.view.is_checked ===
                                                1 ? (
                                                <Link className="dropdown-item" to="/clients">
                                                    {props.t("Clients")}
                                                </Link>
                                            ) : (
                                                ""
                                            )}
                                            {getPermission()?.contacts.contacts.view.is_checked ===
                                                1 ? (
                                                <Link className="dropdown-item" to="/contacts">
                                                    {props.t("Contacts")}
                                                </Link>
                                            ) : (
                                                ""
                                            )}
                                        </div>
                                    </div>
                                </li>
                                <li className="nav-item dropdown">
                                    {getPermission()?.delivery_notes.delivery_notes.view
                                        .is_checked === 0 &&
                                        getPermission()?.orders.orders.view.is_checked === 0 &&
                                        getPermission()?.estimates.estimates.view.is_checked === 0 ? (
                                        ""
                                    ) : (
                                        <Link
                                            className="nav-link"
                                            to="/sales"
                                            onClick={(e) => {
                                                e.preventDefault();
                                                setSale(!sale);
                                            }}
                                        >
                                            {props.t("Sales")}
                                        </Link>
                                    )}
                                    <div className={classname("dropdown-menu", { show: sale })}>
                                        <div className="dropdown">
                                            {getPermission()?.estimates.estimates.view.is_checked ===
                                                1 ? (
                                                <Link className="dropdown-item" to="/sales">
                                                    {props.t("Estimates")}
                                                </Link>
                                            ) : (
                                                ""
                                            )}
                                            {getPermission()?.orders.orders.view.is_checked === 1 ? (
                                                <Link className="dropdown-item" to="/order">
                                                    {props.t("Orders")}
                                                </Link>
                                            ) : (
                                                ""
                                            )}
                                            {getPermission()?.delivery_notes.delivery_notes.view
                                                .is_checked === 1 ? (
                                                <Link className="dropdown-item" to="/delivery">
                                                    {props.t("Delivery Notes")}
                                                </Link>
                                            ) : (
                                                ""
                                            )}
                                        </div>
                                    </div>
                                </li>
                                {localStorage.getItem("tech_module") == "1" ? (
                                    <li className="nav-item dropdown">
                                        {getPermission()?.work_delivery_notes.work_delivery_notes
                                            .view.is_checked === 0 &&
                                            getPermission()?.work_orders.work_orders.view.is_checked ===
                                            0 &&
                                            getPermission()?.work_estimate.work_estimate.view
                                                .is_checked === 0 &&
                                            getPermission()?.incidents.incidents.view.is_checked ===
                                            0 ? (
                                            ""
                                        ) : (
                                            <Link
                                                className="nav-link"
                                                to="/technical-services"
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    setTech(!tech);
                                                }}
                                            >
                                                {props.t("Technical Service")}
                                            </Link>
                                        )}
                                        <div className={classname("dropdown-menu", { show: tech })}>
                                            <div className="dropdown">
                                                {getPermission()?.incidents.incidents.view
                                                    .is_checked === 1 ? (
                                                    <Link
                                                        className="dropdown-item"
                                                        to="/technical-services"
                                                    >
                                                        {props.t("Incidents")}
                                                    </Link>
                                                ) : (
                                                    ""
                                                )}
                                                {getPermission()?.work_estimate.work_estimate.view
                                                    .is_checked === 1 ? (
                                                    <Link className="dropdown-item" to="/estimates">
                                                        {props.t("Estimates")}
                                                    </Link>
                                                ) : (
                                                    ""
                                                )}
                                                {getPermission()?.work_orders.work_orders.view
                                                    .is_checked === 1 ? (
                                                    <Link className="dropdown-item" to="/work-order">
                                                        {props.t("Work Orders")}
                                                    </Link>
                                                ) : (
                                                    ""
                                                )}
                                                {getPermission()?.work_delivery_notes
                                                    .work_delivery_notes.view.is_checked === 1 ? (
                                                    <Link className="dropdown-item" to="/work-delivery">
                                                        {props.t("Work Notes")}
                                                    </Link>
                                                ) : (
                                                    ""
                                                )}
                                            </div>
                                        </div>
                                    </li>
                                ) : (
                                    ""
                                )}{" "}
                                <li className="nav-item dropdown">
                                    {getPermission()?.invoices.invoices.view.is_checked === 0 &&
                                        getPermission()?.normal_invoice_receipts
                                            ?.normal_invoice_receipts.view.is_checked === 0 &&
                                        getPermission()?.refunds.refunds.view.is_checked === 0 &&
                                        getPermission()?.refund_receipts.refund_receipts.view
                                            .is_checked === 0 &&
                                        getPermission()?.invoice_summary.invoice_summary.view
                                            .is_checked === 0 ? (
                                        ""
                                    ) : (
                                        <Link
                                            className="nav-link"
                                            to="/invoicing"
                                            onClick={(e) => {
                                                e.preventDefault();
                                                setInvoice(!invoice);
                                            }}
                                            disabled
                                        >
                                            {props.t("Invoicing")}
                                        </Link>
                                    )}
                                    <div
                                        className={classname("dropdown-menu", { show: invoice })}
                                    >
                                        <div className="dropdown">
                                            {getPermission()?.invoices.invoices.view.is_checked ===
                                                1 ? (
                                                <Link className="dropdown-item" to="/invoicing">
                                                    {props.t("Invoices")}
                                                </Link>
                                            ) : (
                                                ""
                                            )}
                                            {getPermission()?.normal_invoice_receipts
                                                ?.normal_invoice_receipts.view.is_checked === 1 ? (
                                                <Link className="dropdown-item" to="/invoice-receipts">
                                                    {props.t("Invoice Receipts")}
                                                </Link>
                                            ) : (
                                                ""
                                            )}
                                            {getPermission()?.refunds.refunds.view.is_checked ===
                                                1 ? (
                                                <Link className="dropdown-item" to="/refund-invoices">
                                                    {props.t("Refund Invoices")}
                                                </Link>
                                            ) : (
                                                ""
                                            )}
                                            {getPermission()?.refund_receipts.refund_receipts.view
                                                .is_checked === 1 ? (
                                                <Link className="dropdown-item" to="/refund-receipts">
                                                    {props.t("Refund Receipts")}
                                                </Link>
                                            ) : (
                                                ""
                                            )}
                                            {getPermission()?.invoice_summary.invoice_summary.view
                                                .is_checked === 1 ? (
                                                <Link
                                                    className="dropdown-item"
                                                    to="/sent-invoice-summary"
                                                >
                                                    {props.t("Sent Invoice Summary")}
                                                </Link>
                                            ) : (
                                                ""
                                            )}
                                        </div>
                                    </div>
                                </li>
                                <li className="nav-item dropdown">
                                    {getPermission()?.suppliers.suppliers.view.is_checked === 0 &&
                                        getPermission()?.expenses_and_investments
                                            .expenses_and_investments.view.is_checked === 0 &&
                                        getPermission()?.purchase_orders.purchase_orders.view
                                            .is_checked === 0 &&
                                        getPermission()?.purchase_delivery_notes
                                            .purchase_delivery_notes.view.is_checked === 0 &&
                                        getPermission()?.purchase_invoices.purchase_invoices.view
                                            .is_checked === 0 &&
                                        getPermission()?.purchase_invoice_receipts
                                            .purchase_invoice_receipts.view.is_checked === 0 &&
                                        getPermission()?.tickets_and_other_expenses
                                            .tickets_and_other_expenses.view.is_checked === 0 &&
                                        getPermission()?.purchase_invoice_summary
                                            .purchase_invoice_summary.view.is_checked === 0 ? (
                                        ""
                                    ) : (
                                        <Link
                                            className="nav-link"
                                            to="/suppliers"
                                            onClick={(e) => {
                                                e.preventDefault();
                                                setPurchase(!purchase);
                                            }}
                                        >
                                            {props.t("Purchases")}
                                        </Link>
                                    )}
                                    <div
                                        className={classname("dropdown-menu", { show: purchase })}
                                    >
                                        <div className="dropdown">
                                            {getPermission()?.suppliers.suppliers.view.is_checked ===
                                                1 ? (
                                                <Link
                                                    className="dropdown-item hoverChange"
                                                    to="/suppliers"
                                                    style={{ background: "white" }}
                                                >
                                                    {props.t("Suppliers")}
                                                </Link>
                                            ) : (
                                                ""
                                            )}
                                            {getPermission()?.expenses_and_investments
                                                .expenses_and_investments.view.is_checked === 1 ? (
                                                <Link
                                                    className="dropdown-item"
                                                    to="/expenses-and-investments"
                                                >
                                                    {props.t("Expenses And Investments")}
                                                </Link>
                                            ) : (
                                                ""
                                            )}
                                            {getPermission()?.purchase_orders.purchase_orders.view
                                                .is_checked === 1 ? (
                                                <Link
                                                    className="dropdown-item hoverChange"
                                                    to="/purchase-order"
                                                    style={{ background: "white" }}
                                                >
                                                    {props.t("Purchase Orders")}
                                                </Link>
                                            ) : (
                                                ""
                                            )}
                                            {getPermission()?.purchase_delivery_notes
                                                .purchase_delivery_notes.view.is_checked === 1 ? (
                                                <Link
                                                    className="dropdown-item hoverChange"
                                                    to="/purchase-Delivery-Notes"
                                                    style={{ background: "white" }}
                                                >
                                                    {props.t("Purchase Delivery Notes")}
                                                </Link>
                                            ) : (
                                                ""
                                            )}
                                            {getPermission()?.purchase_invoices.purchase_invoices.view
                                                .is_checked === 1 ? (
                                                <Link
                                                    className="dropdown-item hoverChange"
                                                    to="/purchase-invoice"
                                                    style={{ background: "white" }}
                                                >
                                                    {props.t("Purchase Invoice")}
                                                </Link>
                                            ) : (
                                                ""
                                            )}
                                            {getPermission()?.purchase_invoice_receipts
                                                .purchase_invoice_receipts.view.is_checked === 1 ? (
                                                <Link
                                                    className="dropdown-item hoverChange"
                                                    to="/purchase-receipts"
                                                    style={{ background: "white" }}
                                                >
                                                    {props.t("Purchase Receipts")}
                                                </Link>
                                            ) : (
                                                ""
                                            )}
                                            {getPermission()?.tickets_and_other_expenses
                                                .tickets_and_other_expenses.view.is_checked === 1 ? (
                                                <Link
                                                    className="dropdown-item hoverChange"
                                                    to="/tickets-and-expenses"
                                                    style={{ background: "white" }}
                                                >
                                                    {props.t("Tickets And Expenses")}
                                                </Link>
                                            ) : (
                                                ""
                                            )}
                                            {getPermission()?.purchase_invoice_summary
                                                .purchase_invoice_summary.view.is_checked === 1 ? (
                                                <Link
                                                    className="dropdown-item hoverChange"
                                                    to="/purchase-invoice-summary"
                                                    style={{ background: "white" }}
                                                >
                                                    {props.t("Purchase Invoice Summary")}
                                                </Link>
                                            ) : (
                                                ""
                                            )}
                                        </div>
                                    </div>
                                </li>
                                <li className="nav-item dropdown">
                                    <Link
                                        className="nav-link"
                                        to="/reports/overview"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            setReport(!report);
                                        }}
                                    >
                                        {props.t("Report")}
                                    </Link>
                                    <div className={classname("dropdown-menu", { show: report })}>
                                        {getPermission()?.reports.overview.is_checked === 1 ? (
                                            <Link className="dropdown-item" to="/reports/overview">
                                                {props.t("Overview")}
                                            </Link>
                                        ) : (
                                            ""
                                        )}
                                        <div className="dropdown">
                                            {getPermission()?.reports.invoicing_by_client
                                                .is_checked === 0 &&
                                                getPermission()?.reports.invoicing_by_agent.is_checked ===
                                                0 &&
                                                getPermission()?.reports.invoicing_by_item.is_checked ===
                                                0 ? (
                                                ""
                                            ) : (
                                                <Link
                                                    className="dropdown-item"
                                                    to="/reports/Invoicing/byclient"
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        setInvoicing(!invoicing);
                                                    }}
                                                >
                                                    {props.t("Invoicing")}
                                                </Link>
                                            )}
                                            <div
                                                className={classname("dropdown-menu", {
                                                    show: invoicing,
                                                })}
                                            >
                                                {getPermission()?.reports.invoicing_by_client
                                                    .is_checked === 1 ? (
                                                    <Link
                                                        className="dropdown-item"
                                                        to="/reports/Invoicing/byclient"
                                                    >
                                                        {props.t("By Client")}
                                                    </Link>
                                                ) : (
                                                    ""
                                                )}
                                                {getPermission()?.reports.invoicing_by_agent
                                                    .is_checked === 1 ? (
                                                    <Link
                                                        className="dropdown-item"
                                                        to="/reports/Invoicing/byAgent"
                                                    >
                                                        {props.t("By Agent")}
                                                    </Link>
                                                ) : (
                                                    ""
                                                )}
                                                {getPermission()?.reports.invoicing_by_item
                                                    .is_checked === 1 ? (
                                                    <Link
                                                        className="dropdown-item"
                                                        to="/reports/Invoicing/byItems"
                                                    >
                                                        {props.t("By Item")}
                                                    </Link>
                                                ) : (
                                                    ""
                                                )}
                                            </div>
                                        </div>
                                        <div className="dropdown">
                                            {getPermission()?.reports.cash_flow_by_agent
                                                .is_checked === 0 &&
                                                getPermission()?.reports.cash_flow_overview.is_checked ===
                                                0 &&
                                                getPermission()?.reports.cash_flow_by_payment_options
                                                    .is_checked === 0 ? (
                                                ""
                                            ) : (
                                                <Link
                                                    className="dropdown-item"
                                                    to="/reports/cashflow/overview"
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        setCashFlow(!cashFlow);
                                                    }}
                                                >
                                                    {props.t("Cash Flow")}
                                                </Link>
                                            )}
                                            <div
                                                className={classname("dropdown-menu", {
                                                    show: cashFlow,
                                                })}
                                            >
                                                {getPermission()?.reports.cash_flow_overview
                                                    .is_checked === 1 ? (
                                                    <Link
                                                        className="dropdown-item"
                                                        to="/reports/cashflow/overview"
                                                    >
                                                        {props.t("Overview")}
                                                    </Link>
                                                ) : (
                                                    ""
                                                )}
                                                {getPermission()?.reports.cash_flow_by_payment_options
                                                    .is_checked === 1 ? (
                                                    <Link
                                                        className="dropdown-item"
                                                        to="/reports/cashFlow/byPaymentOption"
                                                    >
                                                        {props.t("By Payment Options")}
                                                    </Link>
                                                ) : (
                                                    ""
                                                )}
                                                {getPermission()?.reports.cash_flow_by_agent
                                                    .is_checked === 1 ? (
                                                    <Link
                                                        className="dropdown-item"
                                                        to="/reports/cashFlow/cashFlowAgent"
                                                    >
                                                        {props.t("By Agent")}
                                                    </Link>
                                                ) : (
                                                    ""
                                                )}
                                            </div>
                                        </div>
                                        <div className="dropdown">
                                            {getPermission()?.reports.sales_by_item.is_checked ===
                                                0 &&
                                                getPermission()?.reports.sales_by_agent.is_checked ===
                                                0 &&
                                                getPermission()?.reports.sales_by_client.is_checked ===
                                                0 &&
                                                getPermission()?.reports.sales_overview.is_checked ===
                                                1 ? (
                                                ""
                                            ) : (
                                                <Link
                                                    className="dropdown-item"
                                                    to="/reports/sales/overview"
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        setSaleReport(!saleReport);
                                                    }}
                                                >
                                                    {props.t("Sales")}
                                                </Link>
                                            )}
                                            <div
                                                className={classname("dropdown-menu", {
                                                    show: saleReport,
                                                })}
                                            >
                                                {getPermission()?.reports.sales_overview.is_checked ===
                                                    1 ? (
                                                    <Link
                                                        className="dropdown-item"
                                                        to="/reports/sales/overview"
                                                    >
                                                        {props.t("Overview")}
                                                    </Link>
                                                ) : (
                                                    ""
                                                )}
                                                {getPermission()?.reports.sales_by_client.is_checked ===
                                                    1 ? (
                                                    <Link
                                                        className="dropdown-item"
                                                        to="/reports/sales/sale-by-client"
                                                    >
                                                        {props.t("By Client")}
                                                    </Link>
                                                ) : (
                                                    ""
                                                )}
                                                {getPermission()?.reports.sales_by_agent.is_checked ===
                                                    1 ? (
                                                    <Link
                                                        className="dropdown-item"
                                                        to="/reports/sales/sale-by-agent"
                                                    >
                                                        {props.t("By Agent")}
                                                    </Link>
                                                ) : (
                                                    ""
                                                )}
                                                {getPermission()?.reports.sales_by_item.is_checked ===
                                                    1 ? (
                                                    <Link
                                                        className="dropdown-item"
                                                        to="/reports/sales/sale-by-item"
                                                    >
                                                        {props.t("By Item")}
                                                    </Link>
                                                ) : (
                                                    ""
                                                )}
                                            </div>
                                        </div>
                                        <div className="dropdown">
                                            {getPermission()?.reports.technical_service_overview
                                                .is_checked === 0 &&
                                                getPermission()?.reports.incidents_by_client
                                                    .is_checked === 0 &&
                                                getPermission()?.reports.incidents_by_agent.is_checked ===
                                                0 &&
                                                getPermission()?.reports.technical_service_by_client
                                                    .is_checked === 0 &&
                                                getPermission()?.reports.technical_service_by_agent
                                                    .is_checked === 0 &&
                                                getPermission()?.reports.technical_service_by_item
                                                    .is_checked === 0 ? (
                                                ""
                                            ) : (
                                                <Link
                                                    className="dropdown-item"
                                                    to="/reports/technicalService/overview"
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        setTechReport(!techReport);
                                                    }}
                                                >
                                                    {props.t("Technical Service")}
                                                </Link>
                                            )}
                                            <div
                                                className={classname("dropdown-menu", {
                                                    show: techReport,
                                                })}
                                            >
                                                {getPermission()?.reports.technical_service_overview
                                                    .is_checked === 1 ? (
                                                    <Link
                                                        className="dropdown-item"
                                                        to="/reports/technicalService/overview"
                                                    >
                                                        {props.t("Overview")}
                                                    </Link>
                                                ) : (
                                                    ""
                                                )}
                                                {getPermission()?.reports.incidents_by_client
                                                    .is_checked === 1 ? (
                                                    <Link
                                                        className="dropdown-item"
                                                        to="/reports/technicalService/incidentClient"
                                                    >
                                                        {props.t("Incidents by Client")}
                                                    </Link>
                                                ) : (
                                                    ""
                                                )}
                                                {getPermission()?.reports.incidents_by_agent
                                                    .is_checked === 1 ? (
                                                    <Link
                                                        className="dropdown-item"
                                                        to="/reports/technicalService/incidentAgent"
                                                    >
                                                        {props.t("Incidents by Agent")}
                                                    </Link>
                                                ) : (
                                                    ""
                                                )}
                                                {getPermission()?.reports.technical_service_by_client
                                                    .is_checked === 1 ? (
                                                    <Link
                                                        className="dropdown-item"
                                                        to="/reports/technicalService/tech-by-client"
                                                    >
                                                        {props.t("By Client")}
                                                    </Link>
                                                ) : (
                                                    ""
                                                )}
                                                {getPermission()?.reports.technical_service_by_agent
                                                    .is_checked === 1 ? (
                                                    <Link
                                                        className="dropdown-item"
                                                        to="/reports/technicalService/tech-by-agent"
                                                    >
                                                        {props.t("By Agent")}
                                                    </Link>
                                                ) : (
                                                    ""
                                                )}
                                                {getPermission()?.reports.technical_service_by_item
                                                    .is_checked === 1 ? (
                                                    <Link
                                                        className="dropdown-item"
                                                        to="/reports/technicalService/tech-by-item"
                                                    >
                                                        {props.t("By Item")}
                                                    </Link>
                                                ) : (
                                                    ""
                                                )}
                                            </div>
                                        </div>
                                        <div className="dropdown">
                                            {getPermission()?.reports.purchases_by_provider
                                                .is_checked === 0 &&
                                                getPermission()?.reports.purchases_by_item.is_checked ===
                                                0 ? (
                                                ""
                                            ) : (
                                                <Link
                                                    className="dropdown-item"
                                                    to="/reports/purchases/bySuppliers"
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        setPurchaseReport(!purchaseReport);
                                                    }}
                                                >
                                                    {props.t("Purchases")}
                                                </Link>
                                            )}
                                            <div
                                                className={classname("dropdown-menu", {
                                                    show: purchaseReport,
                                                })}
                                            >
                                                {getPermission()?.reports.purchases_by_provider
                                                    .is_checked === 1 ? (
                                                    <Link
                                                        className="dropdown-item"
                                                        to="/reports/purchases/bySuppliers"
                                                    >
                                                        {props.t("By Supplier")}
                                                    </Link>
                                                ) : (
                                                    ""
                                                )}
                                                {getPermission()?.reports.purchases_by_item
                                                    .is_checked === 1 ? (
                                                    <Link
                                                        className="dropdown-item"
                                                        to="/reports/purchases/purchaseByItem"
                                                    >
                                                        {props.t("By Item")}
                                                    </Link>
                                                ) : (
                                                    ""
                                                )}
                                            </div>
                                        </div>
                                        {getPermission()?.reports.stock_valuation.is_checked ===
                                            1 ? (
                                            <Link
                                                className="dropdown-item"
                                                to="/reports/stockValuation"
                                            >
                                                {props.t("Stock Valuation")}
                                            </Link>
                                        ) : (
                                            ""
                                        )}
                                        <div className="dropdown">
                                            {getPermission()?.reports.purchases_by_provider
                                                .is_checked === 0 &&
                                                getPermission()?.reports.purchases_by_item.is_checked ===
                                                0 ? (
                                                ""
                                            ) : (
                                                <Link
                                                    className="dropdown-item"
                                                    to="/reports/ofevolution/ofProfit"
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        setOfevolutionReport(!ofevolutionReport);
                                                    }}
                                                >
                                                    {props.t("of evolution")}
                                                </Link>
                                            )}
                                            <div
                                                className={classname("dropdown-menu", {
                                                    show: ofevolutionReport,
                                                })}
                                            >
                                                {getPermission()?.reports.purchases_by_provider
                                                    .is_checked === 1 ? (
                                                    <Link
                                                        className="dropdown-item"
                                                        to="/reports/ofevolution/ofProfit"
                                                    >
                                                        {props.t("of Profit")}
                                                    </Link>
                                                ) : (
                                                    ""
                                                )}
                                                {getPermission()?.reports.purchases_by_item
                                                    .is_checked === 1 ? (
                                                    <Link
                                                        className="dropdown-item"
                                                        to="/reports/ofevolution/client"
                                                    >
                                                        {props.t("Invoicing By Client")}
                                                    </Link>
                                                ) : (
                                                    ""
                                                )}
                                                {getPermission()?.reports.purchases_by_item
                                                    .is_checked === 1 ? (
                                                    <Link
                                                        className="dropdown-item"
                                                        to="/reports/ofevolution/agent"
                                                    >
                                                        {props.t("Invoicing By Agent")}
                                                    </Link>
                                                ) : (
                                                    ""
                                                )}
                                                {getPermission()?.reports.purchases_by_item
                                                    .is_checked === 1 ? (
                                                    <Link
                                                        className="dropdown-item"
                                                        to="/reports/ofevolution/item"
                                                    >
                                                        {props.t("Invoicing By Item")}
                                                    </Link>
                                                ) : (
                                                    ""
                                                )}
                                                {getPermission()?.reports.purchases_by_item
                                                    .is_checked === 1 ? (
                                                    <Link
                                                        className="dropdown-item"
                                                        to="/reports/ofevolution/purchasesprovider"
                                                    >
                                                        {props.t("Purchases by Provider")}
                                                    </Link>
                                                ) : (
                                                    ""
                                                )}
                                                {getPermission()?.reports.purchases_by_item
                                                    .is_checked === 1 ? (
                                                    <Link
                                                        className="dropdown-item"
                                                        to="/reports/ofevolution/purchasesItem"
                                                    >
                                                        {props.t("Purchases by Item")}
                                                    </Link>
                                                ) : (
                                                    ""
                                                )}
                                            </div>
                                        </div>
                                        {getPermission()?.reports.view_tax_reports.is_checked ===
                                            1 ? (
                                            <Link className="dropdown-item" to="/reports/taxSummary">
                                                {props.t("Tax Summary")}
                                            </Link>
                                        ) : (
                                            ""
                                        )}
                                    </div>
                                </li>
                            </ul>
                        </Collapse>
                    </nav>
                </div>
            </div>
        </React.Fragment>
    );
};

const mapStatetoProps = (state) => {
    const { leftMenu } = state.Layout;
    return { leftMenu };
};

export default withRouter(
    connect(mapStatetoProps, {})(withTranslation()(Navbar))
);
Navbar.propTypes = {
    t: PropTypes.any,
};
