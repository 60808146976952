import React, { useState, useEffect } from "react";
import { Input } from "reactstrap";
import { AvField } from "availity-reactstrap-validation";
import { getCurrentUser } from "../../helpers/Utils";
const axios = require("axios").default;
import del from "../../assets/images/delete.png";
import ProSerList from "../Pro&SerList";
import Loader from "../../components/Loader";
import DropdownList from "react-widgets/DropdownList";
import NameCustomDropdown from "./Updateproduct/nameDropdown";
import "react-widgets/styles.css";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import SweetAlert from "react-bootstrap-sweetalert";

const ProductTechEstimates = (props) => {
  const [remove, setRemove] = useState(props.i);
  const [product, setProduct] = useState(props.data);
  const [desc, setDesc] = useState(props.data.description);
  const [basePrice, setBasePrice] = useState("");
  const [discount, setDiscount] = useState(props.data.discount);
  const [quan, setQuan] = useState(props.data.quantity);
  const [amount, setAmount] = useState(props.data.amount);
  const [dataProduct, setDataProduct] = useState([]);
  const [dataService, setDataService] = useState([]);
  const [dataTax, setDataTax] = useState([]);
  const [dataIncomeTax, setDataIncomeTax] = useState([]);
  const [defaultTax, setDefaultTax] = useState("");
  const [defaultIncomeTax, setDefaultIncomeTax] = useState("0");
  const [isDisabled, setIsDisabled] = useState(props.isEditable);
  const [selectItemModal, setSelectItemModal] = useState(false);
  const [confirmModal, setConfirmModal] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [newShowpopup, setNewShowpopup] = useState(1);
  const [focus, setFocus] = useState(false);
  const [error, setError] = useState("");
  const [loader, setLoader] = useState(<Loader />);
  const [timer, setTimer] = useState(null);
  const [clientLoader, setClientLoader] = useState(false);
  const [productNameData, setProductNameData] = useState(props.data.name);
  const [typingTimeout, setTypingTimeout] = useState(null);
  const [productRates, setproductRates] = useState("");

  const getProductData = (e) => {
    clearTimeout(timer);
    const newTimer = setTimeout(() => {
      getProduct(e);
      getServices(e);
    }, 1000);
    setTimer(newTimer);
  };
  //   const getServiceData = (e) => {
  //     clearTimeout(timer);
  //     const newTimer = setTimeout(() => {
  //       getServices(e);
  //     }, 1000);
  //     setTimer(newTimer);
  //   };

  const getProduct = (e) => {
    setDataProduct([]);
    setClientLoader(true);
    if (e !== undefined) {
      axios
        .get(
          `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
            "companyId"
          )}/products?search=${e}`,
          {
            headers: {
              Authorization: `Bearer ${getCurrentUser().token}`,
            },
          }
        )
        .then(function (res) {
          //   setLoader("");
          if (res.data.status) {
            // setError("");
            setDataProduct(res.data.products);
          } else {
            setError(res.data.message);
          }
        });
    } else {
      axios
        .get(
          `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
            "companyId"
          )}/products`,
          {
            headers: {
              Authorization: `Bearer ${getCurrentUser().token}`,
            },
          }
        )
        .then(function (res) {
          setLoader("");
          if (res.data.status) {
            setError("");
            setDataProduct(res.data.products);
          } else {
            setError(res.data.message);
          }
          setClientLoader(false);
        });
    }
  };

  const getServices = (e) => {
    setDataService([]);
    if (e !== undefined) {
      axios
        .get(
          `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
            "companyId"
          )}/services?search=${e}`,
          {
            headers: {
              Authorization: `Bearer ${getCurrentUser().token}`,
            },
          }
        )
        .then(function (res) {
          //   setLoader("");
          if (res.data.status) {
            setError("");
            setDataService(res.data.products);
          } else {
            setError(res.data.message);
          }
        });
    } else {
      axios
        .get(
          `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
            "companyId"
          )}/services`,
          {
            headers: {
              Authorization: `Bearer ${getCurrentUser().token}`,
            },
          }
        )
        .then(function (res) {
          setLoader("");
          if (res.data.status) {
            setError("");
            setDataService(res.data.products);
          } else {
            setError(res.data.message);
          }
        });
    }
  };

  const getTaxes = () => {
    setDataTax([]);
    axios
      .get(
        `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
          "companyId"
        )}/cosumption-taxes`,
        {
          headers: {
            Authorization: `Bearer ${getCurrentUser().token}`,
          },
        }
      )
      .then(function (res) {
        if (res.data.status) {
          setDataTax(res.data.taxes);
          res.data.taxes?.map((item) => {
            if (item.by_default === "1") {
              setDefaultTax(item.tax);
            }
          });
        }
      });
  };

  const getIncomeTax = () => {
    setDataIncomeTax([]);
    axios
      .get(
        `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
          "companyId"
        )}/income-taxes`,
        {
          headers: {
            Authorization: `Bearer ${getCurrentUser().token}`,
          },
        }
      )
      .then(function (res) {
        if (res.data.status) {
          setDataIncomeTax(res.data.taxes);
          res.data.taxes?.map((item) => {
            if (item.by_default === "1") {
              setDefaultIncomeTax(item.tax);
            }
          });
        }
      });
  };

  let productOptions = [
    { id: "0", name: "Add Product", reference: "Product" },
    { id: "0", name: "Add Service", reference: "Service" },
  ];
  if (dataProduct !== undefined) {
    dataProduct &&
      dataProduct.map((item) => {
        productOptions.push({
          id: item.id,
          name: `${item.reference}${item.reference_number}`,
          reference: item.reference,
        });
      });
  }

  if (dataService !== undefined) {
    dataService &&
      dataService.map((item) => {
        productOptions.push({
          id: item.id,
          name: `${item.reference}${item.reference_number}`,
          reference: item.reference,
        });
      });
  }

  const selectProduct = (e, i, type) => {
    setproductRates("");
    if (type == "SER") {
      axios
        .get(
          `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
            "companyId"
          )}/services/${e}?client_id=${props.clientId}`,
          {
            headers: {
              Authorization: `Bearer ${getCurrentUser().token}`,
            },
          }
        )
        .then(function (res) {
          if (res.data.status) {
            setProductNameData(res.data.service.name);
            let formValues = props.fields.formValues;
            formValues[i]["reference"] = res.data.service.reference;
            formValues[i]["reference_id"] = res.data.service.id;
            formValues[i]["reference_number"] =
              res.data.service.reference_number;
            formValues[i]["name"] = res.data.service.name;
            formValues[i]["description"] =
              res.data.service.description !== null
                ? res.data.service.description
                : "";
            formValues[i]["base_price"] = res.data.service.price;
            formValues[i]["quantity"] =
              props.statusValue && props.statusValue == "unpaid" ? -1 : 1;
            formValues[i]["discount"] = res.data.service.discount;
            formValues[i]["amount"] =
              props.statusValue && props.statusValue == "unpaid"
                ? -(
                    res.data.service.price -
                    (res.data.service.price * res.data.service.discount) / 100
                  ) * 1
                : (res.data.service.price -
                    (res.data.service.price * res.data.service.discount) /
                      100) *
                  1;
            formValues[i]["vat"] =
              res.data.service.tax !== null ? res.data.service.tax : 0;
            formValues[i]["tax"] = defaultIncomeTax;
            formValues[i]["subtotal"] =
              props.statusValue && props.statusValue == "unpaid"
                ? -(
                    parseFloat(res.data.service.price) +
                    (parseFloat(res.data.service.price) *
                      (parseInt(
                        res.data.service.tax !== null ? res.data.service.tax : 0
                      ) +
                        parseInt(defaultIncomeTax))) /
                      100
                  )
                : parseFloat(res.data.service.price) +
                  (parseFloat(res.data.service.price) *
                    (parseInt(
                      res.data.service.tax !== null ? res.data.service.tax : 0
                    ) +
                      parseInt(defaultIncomeTax))) /
                    100;
            formValues[i]["taxAmount"] =
              props.statusValue && props.statusValue == "unpaid"
                ? -(
                    parseFloat(res.data.service.price) +
                    (parseFloat(res.data.service.price) *
                      parseInt(
                        res.data.service.tax !== null ? res.data.service.tax : 0
                      )) /
                      100 -
                    res.data.service.price
                  )
                : parseFloat(res.data.service.price) +
                  (parseFloat(res.data.service.price) *
                    parseInt(
                      res.data.service.tax !== null ? res.data.service.tax : 0
                    )) /
                    100 -
                  res.data.service.price;
            formValues[i]["otherTaxAmount"] =
              props.statusValue && props.statusValue == "unpaid"
                ? -(
                    parseFloat(res.data.service.price) +
                    (parseFloat(res.data.service.price) *
                      parseInt(parseInt(defaultIncomeTax))) /
                      100 -
                    res.data.service.price
                  )
                : parseFloat(res.data.service.price) +
                  (parseFloat(res.data.service.price) *
                    parseInt(parseInt(defaultIncomeTax))) /
                    100 -
                  res.data.service.price;
            formValues[i]["discAmount"] =
              parseFloat(res.data.service.price) -
              (res.data.service.price -
                (res.data.service.price * res.data.service.discount) / 100) *
                1;
            props.setFields({ formValues });
            // props.setMainFormDisabled(false);
          }
        });
    } else {
      axios
        .get(
          `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
            "companyId"
          )}/products/${e}?client_id=${props.clientId}`,
          {
            headers: {
              Authorization: `Bearer ${getCurrentUser().token}`,
            },
          }
        )
        .then(function (res) {
          if (res.data.status) {
            setProductNameData(res.data.product.name);
            let formValues = props.fields.formValues;
            formValues[i]["reference"] = res.data.product.reference;
            formValues[i]["reference_id"] = res.data.product.id;
            formValues[i]["reference_number"] =
              res.data.product.reference_number;
            formValues[i]["name"] = res.data.product.name;
            formValues[i]["description"] =
              res.data.product.description !== null
                ? res.data.product.description
                : "";
            formValues[i]["base_price"] = res.data.product.price;
            formValues[i]["quantity"] =
              props.statusValue && props.statusValue == "unpaid" ? -1 : 1;
            formValues[i]["discount"] = res.data.product.discount;
            formValues[i]["amount"] =
              props.statusValue && props.statusValue == "unpaid"
                ? -(
                    res.data.product.price -
                    (res.data.product.price * res.data.product.discount) / 100
                  ) * 1
                : (res.data.product.price -
                    (res.data.product.price * res.data.product.discount) /
                      100) *
                  1;
            formValues[i]["vat"] =
              res.data.product.tax !== null ? res.data.product.tax : 0;
            formValues[i]["tax"] = defaultIncomeTax;
            formValues[i]["subtotal"] =
              props.statusValue && props.statusValue == "unpaid"
                ? -(
                    res.data.product.amount +
                    (res.data.product.amount *
                      (parseInt(
                        res.data.product.tax !== null ? res.data.product.tax : 0
                      ) +
                        parseInt(defaultIncomeTax))) /
                      100
                  )
                : res.data.product.amount +
                  (res.data.product.amount *
                    (parseInt(
                      res.data.product.tax !== null ? res.data.product.tax : 0
                    ) +
                      parseInt(defaultIncomeTax))) /
                    100;
            formValues[i]["taxAmount"] =
              props.statusValue && props.statusValue == "unpaid"
                ? -(
                    res.data.product.amount +
                    (res.data.product.amount *
                      parseInt(
                        res.data.product.tax !== null ? res.data.product.tax : 0
                      )) /
                      100 -
                    res.data.product.amount
                  )
                : res.data.product.amount +
                  (res.data.product.amount *
                    parseInt(
                      res.data.product.tax !== null ? res.data.product.tax : 0
                    )) /
                    100 -
                  res.data.product.amount;
            formValues[i]["otherTaxAmount"] =
              props.statusValue && props.statusValue == "unpaid"
                ? -(
                    res.data.product.amount +
                    (res.data.product.amount *
                      parseInt(parseInt(defaultIncomeTax))) /
                      100 -
                    res.data.product.amount
                  )
                : res.data.product.amount +
                  (res.data.product.amount *
                    parseInt(parseInt(defaultIncomeTax))) /
                    100 -
                  res.data.product.amount;
            formValues[i]["discAmount"] =
              parseFloat(res.data.product.price) -
              (res.data.product.price -
                (res.data.product.price * res.data.product.discount) / 100) *
                1;
            props.setFields({ formValues });
            // props.setMainFormDisabled(false);
          }
        });
    }
  };

  const selectProduct2 = (e, i) => {
    setproductRates("");
    if (e.reference == "SER") {
      axios
        .get(
          `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
            "companyId"
          )}/services/${e.id}?client_id=${props.clientId}`,
          {
            headers: {
              Authorization: `Bearer ${getCurrentUser().token}`,
            },
          }
        )
        .then(function (res) {
          if (res.data.status) {
            setProductNameData(res.data.service.name);
            let formValues = props.fields.formValues;
            formValues[i]["reference"] = res.data.service.reference;
            formValues[i]["reference_id"] = res.data.service.id;
            formValues[i]["reference_number"] =
              res.data.service.reference_number;
            formValues[i]["name"] = res.data.service.name;
            formValues[i]["description"] =
              res.data.service.description !== null
                ? res.data.service.description
                : "";
            formValues[i]["base_price"] = res.data.service.price;
            formValues[i]["quantity"] =
              props.statusValue && props.statusValue == "unpaid" ? -1 : 1;
            formValues[i]["discount"] = res.data.service.discount;
            formValues[i]["amount"] =
              props.statusValue && props.statusValue == "unpaid"
                ? -(
                    res.data.service.price -
                    (res.data.service.price * res.data.service.discount) / 100
                  ) * 1
                : (res.data.service.price -
                    (res.data.service.price * res.data.service.discount) /
                      100) *
                  1;
            formValues[i]["vat"] =
              res.data.service.tax !== null ? res.data.service.tax : 0;
            formValues[i]["tax"] = defaultIncomeTax;
            formValues[i]["subtotal"] =
              props.statusValue && props.statusValue == "unpaid"
                ? -(
                    parseFloat(res.data.service.price) +
                    (parseFloat(res.data.service.price) *
                      (parseInt(
                        res.data.service.tax !== null ? res.data.service.tax : 0
                      ) +
                        parseInt(defaultIncomeTax))) /
                      100
                  )
                : parseFloat(res.data.service.price) +
                  (parseFloat(res.data.service.price) *
                    (parseInt(
                      res.data.service.tax !== null ? res.data.service.tax : 0
                    ) +
                      parseInt(defaultIncomeTax))) /
                    100;
            formValues[i]["taxAmount"] =
              props.statusValue && props.statusValue == "unpaid"
                ? -(
                    parseFloat(res.data.service.price) +
                    (parseFloat(res.data.service.price) *
                      parseInt(
                        res.data.service.tax !== null ? res.data.service.tax : 0
                      )) /
                      100 -
                    res.data.service.price
                  )
                : parseFloat(res.data.service.price) +
                  (parseFloat(res.data.service.price) *
                    parseInt(
                      res.data.service.tax !== null ? res.data.service.tax : 0
                    )) /
                    100 -
                  res.data.service.price;
            formValues[i]["otherTaxAmount"] =
              props.statusValue && props.statusValue == "unpaid"
                ? -(
                    parseFloat(res.data.service.price) +
                    (parseFloat(res.data.service.price) *
                      parseInt(parseInt(defaultIncomeTax))) /
                      100 -
                    res.data.service.price
                  )
                : parseFloat(res.data.service.price) +
                  (parseFloat(res.data.service.price) *
                    parseInt(parseInt(defaultIncomeTax))) /
                    100 -
                  res.data.service.price;
            formValues[i]["discAmount"] =
              parseFloat(res.data.service.price) -
              (res.data.service.price -
                (res.data.service.price * res.data.service.discount) / 100) *
                1;
            props.setFields({ formValues });
            // props.setMainFormDisabled(false);
          }
        });
    } else if (e.reference == "PRO") {
      axios
        .get(
          `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
            "companyId"
          )}/products/${e.id}?client_id=${props.clientId}`,
          {
            headers: {
              Authorization: `Bearer ${getCurrentUser().token}`,
            },
          }
        )
        .then(function (res) {
          if (res.data.status) {
            if (res.data.productRates !== null) {
              setproductRates(res.data.productRates);
            } else {
              setproductRates("");
            }
            setBasePrice(res.data.product.price);
            setNewShowpopup(res.data.product.show_popup);
            setProductNameData(res.data.product.name);
            let formValues = props.fields.formValues;
            formValues[i]["reference"] = res.data.product.reference;
            formValues[i]["reference_id"] = res.data.product.id;
            formValues[i]["reference_number"] =
              res.data.product.reference_number;
            formValues[i]["name"] = res.data.product.name;
            formValues[i]["description"] =
              res.data.product.description !== null
                ? res.data.product.description
                : "";
            formValues[i]["base_price"] = res.data.product.price;
            formValues[i]["quantity"] =
              props.statusValue && props.statusValue == "unpaid" ? -1 : 1;
            formValues[i]["discount"] =
              productRates !== ""
                ? productRates.discount
                : res.data.product.discount;
            formValues[i]["amount"] =
              props.statusValue && props.statusValue == "unpaid"
                ? -(
                    res.data.product.price -
                    (res.data.product.price *
                      (productRates !== ""
                        ? productRates.discount
                        : res.data.product.discount)) /
                      100
                  ) * 1
                : (res.data.product.price -
                    (res.data.product.price *
                      (productRates !== ""
                        ? productRates.discount
                        : res.data.product.discount)) /
                      100) *
                  1;
            formValues[i]["vat"] =
              res.data.product.tax !== null ? res.data.product.tax : 0;
            formValues[i]["tax"] = defaultIncomeTax;
            formValues[i]["subtotal"] =
              props.statusValue && props.statusValue == "unpaid"
                ? -(
                    res.data.product.amount +
                    (res.data.product.amount *
                      (parseInt(
                        res.data.product.tax !== null ? res.data.product.tax : 0
                      ) +
                        parseInt(defaultIncomeTax))) /
                      100
                  )
                : res.data.product.amount +
                  (res.data.product.amount *
                    (parseInt(
                      res.data.product.tax !== null ? res.data.product.tax : 0
                    ) +
                      parseInt(defaultIncomeTax))) /
                    100;
            formValues[i]["taxAmount"] =
              props.statusValue && props.statusValue == "unpaid"
                ? -(
                    res.data.product.amount +
                    (res.data.product.amount *
                      parseInt(
                        res.data.product.tax !== null ? res.data.product.tax : 0
                      )) /
                      100 -
                    res.data.product.amount
                  )
                : res.data.product.amount +
                  (res.data.product.amount *
                    parseInt(
                      res.data.product.tax !== null ? res.data.product.tax : 0
                    )) /
                    100 -
                  res.data.product.amount;
            formValues[i]["otherTaxAmount"] =
              props.statusValue && props.statusValue == "unpaid"
                ? -(
                    res.data.product.amount +
                    (res.data.product.amount *
                      parseInt(parseInt(defaultIncomeTax))) /
                      100 -
                    res.data.product.amount
                  )
                : res.data.product.amount +
                  (res.data.product.amount *
                    parseInt(parseInt(defaultIncomeTax))) /
                    100 -
                  res.data.product.amount;
            formValues[i]["discAmount"] =
              parseFloat(res.data.product.price) -
              (res.data.product.price -
                (res.data.product.price *
                  (productRates !== ""
                    ? productRates.discount
                    : res.data.product.discount)) /
                  100) *
                1;
            props.setFields({ formValues });
          }
        });
    } else {
      let formValues = props.fields.formValues;
      formValues[i]["reference"] = e.reference;
      props.setFields({ formValues });
    }
  };

  const removeFields = (e, i) => {
    if (e.length < 1) {
      setProductNameData("");
      let formValues = props.fields.formValues;
      formValues[i]["reference"] = "";
      formValues[i]["reference_id"] = "";
      formValues[i]["reference_number"] = "";
      formValues[i]["name"] = "";
      formValues[i]["description"] = "";
      formValues[i]["base_price"] = "";
      formValues[i]["quantity"] = "";
      formValues[i]["discount"] = "";
      formValues[i]["amount"] = "0";
      formValues[i]["vat"] = "0";
      formValues[i]["tax"] = "0";
      formValues[i]["subtotal"] = "0";
      formValues[i]["taxAmount"] = "0";
      formValues[i]["otherTaxAmount"] = "0";
      formValues[i]["discAmount"] = "0";
      props.setFields({ formValues });
    } else {
      let formValues = props.fields.formValues;
      formValues[i]["name"] = e;
      props.setFields({ formValues });
    }
  };

  useEffect(() => {
    getProduct();
    getServices();
    getTaxes();
    getIncomeTax();
  }, []);

  const removeFormFields = (i) => {
    let formValues = props.fields.formValues;
    // setLoader(<Loader />)
    props.setFields({ formValues: [] });
    formValues.splice(i, 1);
    setTimeout(() => {
      // setLoader('')
      props.setFields({ formValues });
      props.metaDiscount();
    }, 500);
  };

  let updateFields = (e, i) => {
    let amount;
    let subtotal;
    let taxValue;
    let otherTaxValue;
    let discValue;
    let quan = parseFloat(product.quantity);
    let disc =
      productRates
        ? productRates.discount
        : product.discount
        ? parseFloat(product.discount)
        : 0;
    let bPrice = parseFloat(product.base_price);
    let vat = product.vat;
    let tax = product.tax;
    if (e == "updateProduct") {
      let formValues = props.fields.formValues;
      formValues[i]["isUpdate"] = "1";
      formValues[i]["showPopup"] = showPopup ? "1" : "0";
      props.setFields({ formValues });
    }
    if (e == "notUpdateProduct") {
      let formValues = props.fields.formValues;
      formValues[i]["isUpdate"] = "0";
      formValues[i]["showPopup"] = showPopup ? "1" : "0";
      props.setFields({ formValues });
    }
    if (e?.target?.name == "base_price") {
      // if (newShowpopup === 0 && product?.showPopup === "0") {
      //   if (typingTimeout) {
      //     clearTimeout(typingTimeout);
      //   }
        // remove alert
        // const newTimeout = setTimeout(() => {
        //   setConfirmModal(true);
        // }, 1000);
        // setTypingTimeout(newTimeout);
      // }
      
      amount = (e.target.value - (e.target.value * disc) / 100) * quan;
      discValue = e.target.value * quan - amount;
      subtotal = amount + (amount * (parseInt(vat) + parseInt(tax))) / 100;
      taxValue = amount + (amount * parseInt(vat)) / 100 - amount;
      otherTaxValue = amount + ((amount * parseInt(tax)) / 100 - amount);
      setAmount(amount);
      let formValues = props.fields.formValues;
      formValues[i][e.target.name] = e.target.value;
      formValues[i]["amount"] = amount;
      formValues[i]["subtotal"] = subtotal;
      formValues[i]["discAmount"] = discValue;
      formValues[i]["taxAmount"] = taxValue;
      formValues[i]["taxAmount"] = taxValue;
      formValues[i]["otherTaxAmount"] = otherTaxValue;

      props.setFields({ formValues });
    }
    if (e?.target?.name == "quantity") {
      amount = (bPrice - (bPrice * disc) / 100) * parseFloat(e.target.value);
      discValue = bPrice * e.target.value - amount;
      subtotal = amount + (amount * (parseInt(vat) + parseInt(tax))) / 100;
      taxValue = amount + (amount * parseInt(vat)) / 100 - amount;
      otherTaxValue = amount + ((amount * parseInt(tax)) / 100 - amount);
      setAmount(amount);
      let formValues = props.fields.formValues;
      formValues[i][e.target.name] = e.target.value;
      formValues[i]["amount"] = amount;
      formValues[i]["subtotal"] = subtotal;
      formValues[i]["discAmount"] = discValue;
      formValues[i]["taxAmount"] = taxValue;
      formValues[i]["otherTaxAmount"] = otherTaxValue;

      props.setFields({ formValues });
    }
    if (e?.target?.name == "discount") {
      amount = (bPrice - (bPrice * e.target.value) / 100) * quan;
      discValue = bPrice * quan - amount;
      subtotal = amount + (amount * (parseInt(vat) + parseInt(tax))) / 100;
      taxValue = amount + (amount * parseInt(vat)) / 100 - amount;
      otherTaxValue = amount + ((amount * parseInt(tax)) / 100 - amount);
      setAmount(amount);
      let formValues = props.fields.formValues;
      formValues[i][e.target.name] = e.target.value;
      formValues[i]["amount"] = amount;
      formValues[i]["subtotal"] = subtotal;
      formValues[i]["discAmount"] = discValue;
      formValues[i]["taxAmount"] = taxValue;
      formValues[i]["otherTaxAmount"] = otherTaxValue;
      props.setFields({ formValues });
    }
    if (e?.target?.name == "description") {
      let formValues = props.fields.formValues;
      formValues[i][e.target.name] = e.target.value;
      props.setFields({ formValues });
    }
    if (e?.target?.name == "vat") {
      subtotal = subtotal =
        parseFloat(product.amount) +
        (parseFloat(product.amount) *
          (parseFloat(e.target.value) + parseFloat(tax))) /
          100;
      taxValue =
        parseFloat(product.amount) +
        (parseFloat(product.amount) * parseFloat(e.target.value)) / 100 -
        parseFloat(product.amount);
      otherTaxValue =
        parseFloat(product.amount) +
        (parseFloat(product.amount) * parseFloat(tax)) / 100 -
        parseFloat(product.amount);

      let formValues = props.fields.formValues;
      formValues[i][e.target.name] = e.target.value;
      formValues[i]["subtotal"] = subtotal;
      formValues[i]["taxAmount"] = taxValue;
      formValues[i]["otherTaxAmount"] = otherTaxValue;
      props.setFields({ formValues });
    }
    if (e?.target?.name == "tax") {
      subtotal = subtotal =
        parseFloat(product.amount) +
        (parseFloat(product.amount) *
          (parseInt(vat) + parseInt(e.target.value))) /
          100;
      taxValue =
        parseFloat(product.amount) +
        (parseFloat(product.amount) * parseFloat(vat)) / 100 -
        parseFloat(product.amount);
      otherTaxValue =
        parseFloat(product.amount) +
        (parseFloat(product.amount) * parseFloat(e.target.value)) / 100 -
        parseFloat(product.amount);
      let formValues = props.fields.formValues;
      formValues[i][e.target.name] = e.target.value;
      formValues[i]["subtotal"] = subtotal;
      formValues[i]["taxAmount"] = taxValue;
      formValues[i]["otherTaxAmount"] = otherTaxValue;
      props.setFields({ formValues });
    }
    props.metaDiscount();
  };

  const handleBlur = () => {
    console.log(basePrice, 'basePrice====');
    console.log(props.fields.formValues[0].base_price, 'props.fields.formValues[0].base_price====');
    console.log(newShowpopup, '=newShowpopup===');
    console.log(product?.showPopup, '=product?.showPopup===');
    
    if (newShowpopup === 0 && product?.showPopup === "0") {
      if (basePrice != props.fields.formValues[0].base_price) {
        setConfirmModal(true);
      }
    }
  }

  return (
    <>
      {selectItemModal ? (
        <ProSerList
          selectItemModal={selectItemModal}
          setSelectItemModal={setSelectItemModal}
          index={remove}
          //   clients={template}
          data={dataProduct}
          data2={dataService}
          selectProduct={selectProduct}
          getProduct={getProduct}
          getServices={getServices}
          //   getServiceData={getServiceData}
          getProductData={getProductData}
          setMainFormDisabled={props.setMainFormDisabled}
        />
      ) : null}
      <tr className="" id={remove}>
        <td className="td-2">
          <button
            className="btn btn-light ms-3"
            onClick={() => {
              getProduct(),
                getServices(),
                setSelectItemModal(true),
                props.setMainFormDisabled(true);
            }}
            type="button"
            disabled={props.isEditable ? false : true}
          >
            <i className="fas fa-search" />
          </button>
        </td>
        <td className="td-11">
          <div>
            <DropdownList
              data={productOptions}
              dataKey="id"
              textField="name"
              onChange={(e) => selectProduct2(e, remove)}
              disabled={props.isEditable ? false : true}
              // value={`${product.reference} ${product.reference_id} ${product.name}`}
              value={`${product.reference}${product.reference_number}`}
            />
          </div>
          <Input
            type="text"
            name="reference_id"
            defaultValue={product.reference_number}
            disabled={props.isEditable ? false : true}
            hidden
          />
          <Input
            type="text"
            name="reference"
            defaultValue={product.reference}
            disabled={props.isEditable ? false : true}
            hidden
          />
        </td>
        <td className="td-21">
          <div className="client_custom-dropdown">
            <NameCustomDropdown
              getProductData={getProductData}
              getProduct={getProduct}
              getServices={getServices}
              data={dataProduct}
              data2={dataService}
              clientLoader={clientLoader}
              setMainFormDisabled={props.setMainFormDisabled}
              remove={remove}
              productNameData={productNameData}
              setProductNameData={setProductNameData}
              isEditable={props.isEditable}
              //   product={product}
              selectProduct2={selectProduct2}
              removeFields={removeFields}
            />
          </div>
        </td>
        <td className="td-21">
          <AvField
            type={focus ? "textarea" : "textarea"}
            name="description"
            onFocus={() => setFocus(true)}
            onBlur={() => setFocus(false)}
            onChange={(e) => updateFields(e, remove)}
            className="form-control input-style"
            value={product.description}
            disabled={props.isEditable ? false : true}
          />
        </td>
        <td className="td-6">
          <AvField
            type="number"
            name="base_price"
            onChange={(e) => updateFields(e, remove)}
            onBlur={()=>handleBlur()}
            className="form-control input-style"
            value={
              productRates && productRates.sales_price !== undefined
                ? productRates.sales_price
                : product && product.base_price !== undefined
                ? product.base_price
                : ""
            }
            disabled={!props.isEditable}
          />
        </td>
        <td className="td-5">
          <AvField
            type="number"
            name="quantity"
            onChange={(e) => updateFields(e, remove)}
            className="form-control input-style"
            value={product.quantity}
            disabled={props.isEditable ? false : true}
          />
        </td>
        <td className="td-5">
          <AvField
            type="number"
            name="discount"
            className="form-control input-style"
            onChange={(e) => updateFields(e, remove)}
            value={productRates !== "" ? 0 : product.discount}
            disabled={props.isEditable ? false : true}
          />
        </td>
        <td className="td-10">
          <input
            value={
              productRates && productRates.sales_price
                ? productRates.sales_price
                : product.amount == ""
                ? ""
                : parseFloat(product.amount).toFixed(2)
            }
            name="amount"
            type="text"
            className="form-control"
            disabled={props.isEditable ? false : true}
          />
        </td>
        <td className="td-7">
          <AvField
            type="select"
            name="vat"
            className="form-control input-style"
            onChange={(e) => updateFields(e, remove)}
            value={product.vat}
            disabled={props.isEditable ? false : true}
          >
            {dataTax?.map((item, i) => (
              <option value={item.tax} key={i}>
                {item.primary_name} {item.tax}%
              </option>
            ))}
            <option value="0">{props.t("Do not apply")}</option>
          </AvField>
        </td>
        <td className="td-7">
          <AvField
            type="select"
            name="tax"
            className="form-control input-style"
            onChange={(e) => updateFields(e, remove)}
            value={product.tax}
            disabled={props.isEditable ? false : true}
          >
            {dataIncomeTax?.map((item, i) => (
              <option value={item.tax} key={i}>
                {item.tax}% {item.name}
              </option>
            ))}
            <option value="0">{props.t("Do not apply")}</option>
          </AvField>
        </td>
        <td className="td-3">
          <button
            type="button-input"
            className="button-input remove-input barcode-btn"
            onClick={() => removeFormFields(remove)}
            disabled={props.isEditable ? false : true}
          >
            <img src={del} height="25px" />
          </button>
        </td>
      </tr>
      {confirmModal ? (
        <SweetAlert
          title={props.t("Do you want to update the price of the product?")}
          cancelBtnText={props.t("Cancel")}
          confirmBtnText={props.t("Accept")}
          warning
          showCancel
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          onConfirm={() => {
            updateFields("updateProduct", remove);
            setConfirmModal(false);
          }}
          onCancel={() => {
            setConfirmModal(false);
            updateFields("notUpdateProduct", remove);
          }}
        >
          <AvField
            type="checkbox"
            name="showPopup"
            label={props.t("Does not show this message again for this product")}
            onChange={(e) => {
              setShowPopup(e.target.checked);
            }}
            className="form-checkbox"
            // value={showPopup}
            // disabled={props.isEditable ? false : true}
          />
        </SweetAlert>
      ) : null}
    </>
  );
};
export default withTranslation()(ProductTechEstimates);
ProductTechEstimates.propTypes = {
  t: PropTypes.any,
};
