/* eslint-disable use-isnan */
import React, { useState, useEffect } from "react";
import MetaTags from "react-meta-tags";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { AvForm, AvField } from "availity-reactstrap-validation";
import {
  Nav,
  Alert,
  NavItem,
  NavLink,
  Card,
  CardBody,
  Col,
  Container,
  Row,
  TabContent,
  TabPane,
  Spinner,
  Modal,
  Input,
} from "reactstrap";
// import uploadImg from "../../assets/images/def-img.svg";
import classnames from "classnames";
import CategoryModal from "../Category/category-modal";
import Select from "react-select";
import SweetAlert from "react-bootstrap-sweetalert";
import { getCurrentUser } from "../../helpers/Utils";
const axios = require("axios").default;
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import uploadImg from "../../assets/images/def-img.svg";
import Loader from "../../components/Loader";
import ClientAddress from "../../components/ClientAddresses";
import ProductTechEstimates from "../../components/Product/estimates";
import ClientWorkAddress from "../../components/ClientAddresses/workAddress";
import NewPayment from "../../components/PaymentOption/payment_option";
import PayTermModal from "../../components/paymentTerm";
import ClientList from "../../components/ClientList";
import AssetsNew from "../../components/Assests/assests-new";
import { Link } from "react-router-dom";
import DeliveryOptionModal from "../../components/DeliveryOption/modal";
import ClientCustomDropdown from "../../components/ClientDropdown";
import AssignUserList from "../../components/AssignUserList";
import { useHistory } from "react-router-dom";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";

let totalAmount;
let subTotal;
let subTotalShow;
let dValue;
let totalBasePrice;
let totalDisc;
let totalTax;
let otherTax;

let searchedClient;

const InvoicingService = (props) => {
  const history = useHistory();
  const [data, setData] = useState([]);
  const [bank, setBank] = useState(false);
  const [newDisable, setNewDisable] = useState(false);
  const [clients, setClients] = useState("");
  const [workData, setWorkData] = useState("");
  const [clientNameData, setClientNameData] = useState("");
  const [isOpenAddress, setIsOpenAddress] = useState(false);
  const [isWorkAddress, setIsWorkAddress] = useState(false);
  const [allClients, setAllClients] = useState([]);
  const [clientData, setClientData] = useState("");
  const [assetData, setAssetData] = useState("");
  const [loader, setLoader] = useState(<Loader />);
  const [imgPreview, setImgPreview] = useState(uploadImg);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [customActiveTab, setCustomActiveTab] = useState("1");
  const [image, setImage] = useState("");
  const [selectedCate, setSelectedCate] = useState("");
  const [assetId, setAssetId] = useState([]);
  const [startDate, setStartDate] = useState(new Date());
  const [startDate2, setStartDate2] = useState("");
  const [emailDate, setEmailDate] = useState("");
  const [allCategories, setAllCategories] = useState([]);
  const [referenceType, setReferenceType] = useState("pro");
  const [discountValue, setDiscountValue] = useState(0);
  const [paymentData, setPaymentData] = useState("");
  const [payTermModal, setPayTermModal] = useState(false);
  const [payTermLoader, setPayTermLoader] = useState(false);
  const [payTerm, setPayTerm] = useState("");
  const [isOpenModalNew, setIsOpenModalNew] = useState(false);
  const [assetModal, setassetModal] = useState(false);
  const [selectClientModal, setSelectClientModal] = useState(false);
  const [selectClientModal2, setSelectClientModal2] = useState(false);
  const [selectClientId, setSelectClientId] = useState("");
  const [selectClientId2, setSelectClientId2] = useState("");
  const [paymentLoader, setPaymentLoader] = useState(false);
  const [selectedCates, setSelectedCates] = useState("");
  const [deliveryOption, setDeliveryOption] = useState("");
  const [deliveryOptionModal, setDeliveryOptionModal] = useState(false);
  const [deliveryOptionLoader, setDeliveryOptionLoader] = useState(false);
  const [selectedDeliveryOption, setSelectedDeliveryOption] = useState("");
  const [client2, setClient2] = useState("");
  const [clientLoader, setClientLoader] = useState(false);
  const [mainFormDisabled, setMainFormDisabled] = useState(false);
  const [allBanks, setAllBanks] = useState([]);
  const [assignedModal, setAssignedModal] = useState(false);
  const [assignedModal2, setAssignedModal2] = useState(false);
  const [clientFull, setClientFull] = useState("");
  const [assignedUserName, setAssignedUserName] = useState(
    getCurrentUser().name
  );
  const [assignedUserId, setAssignedUserId] = useState(getCurrentUser().id);
  const [agentUserName, setAgentUserName] = useState(getCurrentUser().email);
  const [agentUserId, setAgentUserId] = useState(getCurrentUser().id);
  const [assignUsers, setAssignUsers] = useState([]);
  const [paymentOption, setPaymentOption] = useState("");
  const [clientSelect, setClientSelect] = useState("");
  const [allRates, setAllRates] = useState([]);
  const [timer, setTimer] = useState(null);
  const [paymentTerm, setPaymentTerm] = useState("");
  const [allRef, setAllRef] = useState([]);
  const [defaultRef, setDefaultRef] = useState("");
  const [paidStatus, setPaidStatus] = useState(false);
  const [error4, setError4] = useState("");
  const [exceededLimit, setExceededLimit] = useState(false);
  const [paginationData, setPaginationData] = useState("");
  const [fields, setFields] = useState({
    formValues: [
      {
        reference: "",
        reference_id: "",
        reference_number: "",
        name: "",
        description: "",
        base_price: "",
        quantity: "1",
        discount: "",
        amount: "0",
        vat: "0",
        tax: "0",
        subtotal: "0",
        discAmount: "0",
        taxAmount: "0",
        otherTaxAmount: "0",
        showPopup: "0",
        isUpdate: "0",
      },
    ],
  });
  const [defaultDeliveyOption, setDefaultDeliveryOption] = useState({});

  const getClients = (e) => {
    clearTimeout(timer);
    const newTimer = setTimeout(() => {
      getClientData(e);
    }, 1000);
    setTimer(newTimer);
  };

  const productCreateSubmit = (e, value) => {
    setLoading(false);
    let setPaid = "1";
    if (e.target.set_as_paid.checked) {
      setPaid = "1";
    } else {
      setPaid = "0";
    }
    const formData = new FormData();
    formData.append("reference", value.reference);
    formData.append("reference_number", e.target.reference_number.value);
    formData.append("client_id", selectClientId);
    formData.append("status", e.target.status.value);
    formData.append("title", value.title);
    formData.append("payment_option", e.target.payment_option.value);
    formData.append("payment_term", value.payment_term);
    formData.append("date", e.target.date.value);
    formData.append("sent_date", e.target.sent_date.value);
    // formData.append("agent_id", value.agent_id);
    formData.append("rate", value.rate);
    formData.append("name", value.name);
    formData.append("description", value.description);
    formData.append("set_as_paid", setPaid);
    formData.append("delivery_option", e.target.delivery_option.value);
    formData.append(
      "inv_address",
      value.inv_address !== null ? value.inv_address : ""
    );
    formData.append("private_comments", e.target.private_comments.value);
    formData.append(
      "del_address",
      value.del_address !== null ? value.del_address : ""
    );
    formData.append("email_sent_date", e.target.email_sent_date.value);
    // formData.append("created_from", value.created_from);
    formData.append(
      "bank_account",
      value.bank_account !== undefined ? value.bank_account : ""
    );
    formData.append("currency", value.currency);
    formData.append("tin", value.tin);
    formData.append("currency_rate", e.target.currency_rate.value);
    formData.append("comments", value.comments);
    formData.append("private_comments", e.target.private_comments.value);
    formData.append("addendum", value.addendum);
    formData.append("meta_discount", e.target.meta_discount.value);
    formData.append("signature", image);
    
    const arrValues = assetId.map(item => item.value);
    const valuesString = arrValues.join(',');
    formData.append("asset_id", valuesString);

    formData.append("created_by", assignedUserId);
    formData.append("agent_id", agentUserId);
    formData.append("allow_exceeded_range", exceededLimit ? 1 : 0);
    formData.append("item", JSON.stringify(fields.formValues));
    axios
      .post(
        `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
          "companyId"
        )}/invoices`,
        formData,
        {
          headers: {
            "Content-type": "multipart/form-data",
            Authorization: `Bearer ${getCurrentUser().token}`,
          },
        }
      )
      .then(function (res) {
        setLoading(false);
        if (res.data.status) {
          setError("");
          setSuccess(res.data.message);
          setTimeout(() => {
            setExceededLimit(false);
            history.push(`/invoicing-service/${res.data.data.id}`);
          }, 1000);
        } else {
          if (res.data.message === "Sequence Range Exceeded.") {
            setExceededLimit(true);
          } else {
            setError(res.data.message);
          }

          setSuccess("");
          if (res.data.message === "Ced/Ruc number is required") {
            setCustomActiveTab("5");
          }
        }
        setTimeout(() => {
          setError("");
          setSuccess("");
        }, 4000);
      });
  };
  const toggleCustom = (tab) => {
    if (customActiveTab !== tab) {
      setCustomActiveTab(tab);
    }
  };

  function handleSelectDeliveryOption(e) {
    setSelectedDeliveryOption(e.value);
  }

  const getDeliveryOption = () => {
    setDeliveryOptionLoader(true);
    axios
      .get(
        `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
          "companyId"
        )}/delivery-options`,
        {
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${getCurrentUser().token}`,
          },
        }
      )
      .then(function (res) {
        setDeliveryOption(res.data.delivery_options);
        res.data.delivery_options?.map((item) => {
          if (item.by_default === "1") {
            setDefaultDeliveryOption({ value: item.id, label: item.name });
          }
        });
        setDeliveryOptionLoader(false);
      });
  };

  let selectDeliveryOption = [];
  if (deliveryOption !== undefined) {
    {
      deliveryOption &&
        deliveryOption.map((item) => {
          selectDeliveryOption.push({
            value: item.id,
            label: item.name,
          });
        });
    }
  }

  const NewpaymentShow = () => {
    setPaymentLoader(true);
    axios
      .get(
        `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
          "companyId"
        )}/payment-options`,
        {
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${getCurrentUser().token}`,
          },
        }
      )
      .then(function (res) {
        setPaymentData(res.data.PaymentOptions);
        setPaymentLoader(false);
      });
  };
  const getAllBanks = () => {
    axios
      .get(
        `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
          "companyId"
        )}/bank_accounts`,
        {
          headers: {
            Authorization: `Bearer ${getCurrentUser().token}`,
          },
        }
      )
      .then(function (res) {
        if (res.data.status) {
          setAllBanks(res.data.bank_accounts);
          setError("");
        } else {
          setError(res.data.message);
        }
      });
  };
  const getReferences = () => {
    setAllRef([]);
    axios
      .get(
        `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
          "companyId"
        )}/references?type=Normal Invoice`,
        {
          headers: {
            Authorization: `Bearer ${getCurrentUser().token}`,
          },
        }
      )
      .then(function (res) {
        // setLoader("");
        if (res.data.status) {
          setError("");
          setAllRef(res.data.reference);
          res.data.reference?.map((item) => {
            if (item.by_default === "1") {
              setDefaultRef(item.prefix);
            }
          });
        } else {
          //   setError(res.data.message);
        }
      });
  };

  const getClientData = (e) => {
    setClientLoader(true);
    setAllClients([]);
    let url = `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
      "companyId"
    )}/get-all-clients`;
    if (e !== undefined) {
      searchedClient = e;
      url = `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
        "companyId"
      )}/get-all-clients?search=${searchedClient}`;
    }
    axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${getCurrentUser().token}`,
        },
      })
      .then(function (res) {
        if (res.data.status) {
          setAllClients(res.data.clients.data);
          setPaginationData(res.data.clients);
        } else {
          setAllClients([]);
          setError(res.data.message);
        }
        setClientLoader(false);
        setTimeout(() => {
          setError("");
          //   setSuccess("");
        }, 2000);
      });
  };

  const paginateData = (link) => {
    setPaginationData("");
    setAllClients([]);
    setClientLoader(true);
    let url = `${link}&search=${searchedClient ? searchedClient : ""}`;
    axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${getCurrentUser().token}`,
        },
      })
      .then(function (res) {
        setClientLoader(false);
        if (res.data.status) {
          setError("");
          setAllClients(res.data.clients.data);
          setPaginationData(res.data.clients);
        } else {
          setError(res.data.message);
        }
      });
  };

  const getAssignUser = () => {
    axios
      .get(
        `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
          "companyId"
        )}/users`,
        {
          headers: {
            Authorization: `Bearer ${getCurrentUser().token}`,
          },
        }
      )
      .then(function (res) {
        if (res.data.status) {
          setAssignUsers(res.data.users);
        }
      });
  };

  const getAllRates = () => {
    axios
      .get(
        `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
          "companyId"
        )}/rates`,
        {
          headers: {
            Authorization: `Bearer ${getCurrentUser().token}`,
          },
        }
      )
      .then(function (res) {
        if (res.data.status) {
          setAllRates(res.data.rates);
          setError("");
        } else {
          setError(res.data.message);
        }
      });
  };

  useEffect(() => {
    getReferences();
    NewpaymentShow();
    getClientData();
    getAllBanks();
    getAssignUser();
    getAllRates();
  }, []);

  const getAssignedUser = (id, name) => {
    setAssignedUserId(id);
    setAssignedUserName(name);
  };
  const getAgentUser = (id, name) => {
    setAgentUserId(id);
    setAgentUserName(name);
  };
  let optionPayment = [];
  if (paymentData !== undefined) {
    {
      paymentData &&
        paymentData.map((payment) => {
          optionPayment.push({
            value: payment.id,
            label: payment.name,
          });
        });
    }
  }

  function handleSelectCategoryAll(selectedcate) {
    setSelectedCates(selectedcate);
    // if (selectedcate === "1") {
    //   setBank(true);
    // } else {
    //   setBank(false);
    // }
  }

  function handleAssets(selectedcate) {
    if (!selectedcate) {
      selectedcate = [];
    }
    setAssetId(selectedcate)
  }

  const addFormFields = () => {
    setFields({
      formValues: [
        ...fields.formValues,
        {
          reference: "",
          reference_id: "",
          reference_number: "",
          name: "",
          description: "",
          base_price: "",
          quantity: "1",
          discount: "",
          amount: "0",
          vat: "0",
          tax: "0",
          subtotal: "0",
          discAmount: "0",
          taxAmount: "0",
          otherTaxAmount: "0",
          showPopup: "0",
          isUpdate: "0",
        },
      ],
    });
  };

  totalAmount = fields.formValues.reduce(
    (totalAmount, currentValue) =>
      (totalAmount = totalAmount + parseFloat(currentValue.amount)),
    0
  );

  subTotal = fields.formValues.reduce(
    (subTotal, currentValue) =>
      (subTotal = subTotal + parseFloat(currentValue.subtotal)),
    0
  );
  subTotalShow = subTotal - (subTotal * discountValue) / 100;
  dValue = (subTotal - subTotalShow).toFixed(2);

  totalBasePrice = fields.formValues.reduce(
    (totalBasePrice, currentValue) =>
      (totalBasePrice = totalBasePrice + parseFloat(currentValue.amount)),
    0
  );
  totalDisc = fields.formValues.reduce(
    (totalDisc, currentValue) =>
      (totalDisc = totalDisc + parseFloat(currentValue.discAmount)),
    0
  );
  totalTax = fields.formValues.reduce(
    (totalTax, currentValue) =>
      (totalTax = totalTax + parseFloat(currentValue.taxAmount)),
    0
  );
  otherTax = fields.formValues.reduce(
    (otherTax, currentValue) =>
      (otherTax = otherTax + parseFloat(currentValue.otherTaxAmount)),
    0
  );
  const metaDiscount = (e) => {
    if (e == undefined) {
      subTotal = fields.formValues.reduce(
        (subTotal, currentValue) =>
          (subTotal = subTotal + parseFloat(currentValue.subtotal)),
        0
      );
      subTotalShow = subTotal - (subTotal * discountValue) / 100;
      dValue = (subTotal - subTotalShow).toFixed(2);
    } else {
      setDiscountValue(e);
      subTotalShow = subTotal - (subTotal * e) / 100;
      dValue = (subTotal - subTotalShow).toFixed(2);
    }
  };

  const setComImg = (e) => {
    setImage(e.target.files[0]);
    setImgPreview(URL.createObjectURL(e.target.files[0]));
  };

  const getProducts = () => {
    axios
      .get(
        `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
          "companyId"
        )}/products`,
        {
          headers: {
            Authorization: `Bearer ${getCurrentUser().token}`,
          },
        }
      )
      .then(function (res) {
        if (res.data.status) {
          setError("");
          setData(res.data.products);
        } else {
          setError(res.data.message);
        }
      });
  };

  const clientDetail = (e) => {
    setSelectClientId(e.id);
    setClientNameData(e.legal_name);
    setClientData(e.address);
    setWorkData(e.address);
    setClients(e);
    //   getSingleClientsAddress(e.id);
    AssetsData(e.id);
    setPaymentOption(e.payment_option_id);

    setNewDisable(true);
  };

  const clientDetail2 = (e) => {
    setSelectClientId2(e);
    let index = allClients.findIndex((x) => x.id == e);
    if (index != -1) {
      setClient2(allClients[index].name);
    }
  };

  const getSingleClientsAddress = (e) => {
    axios
      .get(
        `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
          "companyId"
        )}/client-addresses?client_id=${e}`,
        {
          headers: {
            Authorization: `Bearer ${getCurrentUser().token}`,
          },
        }
      )
      .then(function (res) {
        // setAllAddress(res.data.client_addresses);
      });
  };

  const productCategories = () => {
    axios
      .get(
        `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
          "companyId"
        )}/product_categories`,
        {
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${getCurrentUser().token}`,
          },
        }
      )
      .then(function (res) {
        setAllCategories(res.data.product_categories);
      });
  };

  const AssetsData = (e) => {
    axios
      .get(
        `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
          "companyId"
        )}/client-assets?client_id=${e}`,
        {
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${getCurrentUser().token}`,
          },
        }
      )
      .then(function (res) {
        setAssetData(res.data.client_assets);
      });
  };

  let optionAssets = [];
  if (assetData !== undefined) {
    {
      assetData &&
        assetData.map((asset) => {
          optionAssets.push({
            value: asset.id,
            label: asset.name,
          });
        });
    }
  }

  let optionCategory = [];
  if (allCategories !== undefined) {
    {
      allCategories &&
        allCategories.map((category) => {
          optionCategory.push({
            value: category.id,
            label: category.name,
          });
        });
    }
  }

  function handleSelectCategory(selectedcate) {
    setSelectedCate(selectedcate.value);
  }

  const getType = (e) => {
    setReferenceType(e.target.value);
  };

  const getAllPayment = () => {
    setPaymentData([]);
    setPayTermLoader(true);
    axios
      .get(
        `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
          "companyId"
        )}/payment-terms`,
        {
          headers: {
            Authorization: `Bearer ${getCurrentUser().token}`,
          },
        }
      )
      .then(function (res) {
        if (res.data.status) {
          setError("");
          setPaymentTerm(res.data.payment_terms);
          setPayTermLoader(false);
        } else {
          setError(res.data.message);
        }
      });
  };

  useEffect(() => {
    productCategories();
    getAllPayment();
    getProducts();
    getDeliveryOption();
  }, []);

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Invoicing | Clouget</title>
        </MetaTags>
        {isOpenAddress ? (
          <ClientAddress
            setIsOpenModal={setIsOpenAddress}
            clients={clients}
            setClientData={setClientData}
            setWorkData={setWorkData}
            setClientNameData={setClientNameData}
          />
        ) : (
          ""
        )}
        {deliveryOptionModal ? (
          <DeliveryOptionModal
            setDeliveryOptionModal={setDeliveryOptionModal}
            getDeliveryOption={getDeliveryOption}
          />
        ) : (
          ""
        )}
        {isWorkAddress ? (
          <ClientWorkAddress
            setIsOpenModal={setIsWorkAddress}
            clients={clients}
            setWorkData={setWorkData}
            setClientNameData={setClientNameData}
          />
        ) : (
          ""
        )}
        <CategoryModal
          isOpenModal={isOpenModal}
          setIsOpenModal={setIsOpenModal}
          getAllCategories={productCategories}
        />
        <Container fluid>
          <div className="create_company">
            <AvForm
              className="form-horizontal"
              onValidSubmit={(e, v) => {
                productCreateSubmit(e, v);
              }}
              disabled={mainFormDisabled ? true : false}
            >
              <div className="page-title-box">
                <Row className="align-items-center">
                  <Col md={6}>
                    <h6 className="page-title">
                      {getCurrentUser().rimpe_regime_type === "popular" ? (
                        <>{props.t("Sales Note")}</>
                      ) : (
                        <>{props.t("Normal Invoice")}</>
                      )}
                    </h6>
                  </Col>
                  <Col md={6}>
                    <div className="float-end d-flex">
                      {loading ? (
                        <button
                          className="btn btn-primary  disabled"
                          type="button"
                          disabled
                        >
                          {props.t("Loading")}
                        </button>
                      ) : (
                        <button className="btn btn-primary " type="submit">
                          {props.t("Submit")}
                        </button>
                      )}
                    </div>
                  </Col>
                </Row>
              </div>
              <div
                className={`${exceededLimit ? "d-block" : "d-none"
                  } modal fade show`}
                role="dialog"
              >
                <div
                  className={`${exceededLimit ? "" : "d-none"
                    } modal-content modal-dialog `}
                >
                  <div className="modal-body">
                    <Alert color="danger text-center">
                      <p>
                        {props.t(
                          "Sequence of the authorized range has been exceeded."
                        )}
                      </p>
                    </Alert>

                    <p className="fs-4 text-center">
                      {props.t(
                        "Are you sure, you want to generate invoice with exceeded limit?"
                      )}
                    </p>

                    <div className="float-end d-flex">
                      <button
                        className="btn btn-primary me-2 "
                        type="button"
                        onClick={() => setExceededLimit(false)}
                      >
                        {props.t("Cancel")}
                      </button>
                      {loading ? (
                        <button
                          className="btn btn-primary  disabled"
                          type="button"
                          disabled
                        >
                          {props.t("Loading")}
                        </button>
                      ) : (
                        <button className="btn btn-primary " type="submit">
                          {props.t("Submit")}
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              {success ? (
                <Alert color="success">{props.t(success)}</Alert>
              ) : null}
              {error ? (
                <Alert color="danger">
                  <p
                    dangerouslySetInnerHTML={{
                      __html: props.t(error),
                    }}
                  />
                </Alert>
              ) : null}
              {error4 ? <Alert color="danger">{props.t(error4)}</Alert> : null}
              <Card>
                <CardBody>
                  <Row>
                    <Col md={6}>
                      <div className="mb-3">
                        <label>{props.t("Reference")}:</label>
                        <div className="d-flex">
                          <AvField
                            style={{
                              width: "100px",
                            }}
                            type="select"
                            name="reference"
                            value={defaultRef}
                            onChange={(e) => getType(e)}
                          >
                            {allRef &&
                              allRef.map((item, i) => (
                                <option value={item.prefix} key={i}>
                                  {item.prefix}
                                </option>
                              ))}
                          </AvField>
                          <div className="w-100 ps-3">
                            <Input
                              className="w-100 quantity"
                              type="number"
                              step="1"
                              min="1"
                              name="reference_number"
                              placeholder={props.t("Automatic")}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="mb-3 required">
                        <label>{props.t("Client")}:</label>
                        <div className="d-flex">
                          <div className="pe-2 w-100 client_custom-dropdown">
                            <ClientCustomDropdown
                              data={allClients}
                              getClients={getClients}
                              getClientData={getClientData}
                              clientLoader={clientLoader}
                              setMainFormDisabled={setMainFormDisabled}
                              setClientSelect={setClientSelect}
                              setClientAddress={setClientData}
                              setClientFull={setClientFull}
                              clientNameData={clientNameData}
                              setClientNameData={setClientNameData}
                              clientDetail={clientDetail}
                              getAssests={AssetsData}
                              paginationData={paginationData}
                              paginateData={paginateData}
                            />
                          </div>
                          <div className="pe-2">
                            <button
                              className="btn btn-light"
                              onClick={() => {
                                setSelectClientModal(true), getClientData();
                              }}
                              type="button"
                            >
                              <i className="fas fa-search" />
                            </button>
                          </div>
                          <Link
                            className={`btn btn-light ${selectClientId == "" ? "disabled" : ""
                              }`}
                            to={`/client/${selectClientId}`}
                            target="_blank"
                          >
                            <i className="fas fa-eye" />
                          </Link>
                        </div>
                      </div>
                      <div className="mb-3">
                        <label>{props.t("Payment Option")}</label>
                        <div className="d-flex justify-content-between">
                          {paymentLoader ? (
                            <div className="pe-2">
                              <Spinner
                                animation="border"
                                role="status"
                              ></Spinner>
                            </div>
                          ) : (
                            ""
                          )}
                          <div className="w-100 pe-2">
                            <AvField
                              type="select"
                              name="payment_option"
                              onChange={(e) =>
                                handleSelectCategoryAll(e.target.value)
                              }
                              value={paymentOption}
                            >
                              <option value="" hidden>
                                {props.t("Select")}
                              </option>
                              {paymentData &&
                                paymentData.map((item, i) => (
                                  <option value={item.id} key={i}>
                                    {item.name}
                                  </option>
                                ))}
                            </AvField>
                          </div>
                          <button
                            className="btn btn-primary waves-effect waves-light"
                            onClick={() => setIsOpenModalNew(true)}
                            style={{
                              whiteSpace: "nowrap",
                            }}
                            type="button"
                          >
                            + {props.t("New")}
                          </button>
                        </div>
                      </div>

                      {bank ? (
                        <div className="mb-3">
                          <AvField
                            label={props.t("Bank Account")}
                            type="select"
                            name="bank_account"
                          >
                            <option value="no_account">
                              {props.t("Without bank account")}
                            </option>
                            {allBanks &&
                              allBanks.map((bank, i) => (
                                <option value={bank.id} key={i}>
                                  {bank.name}
                                </option>
                              ))}
                          </AvField>
                        </div>
                      ) : (
                        ""
                      )}
                      <div className="mb-3">
                        <AvField
                          label={props.t("Title")}
                          type="text"
                          name="title"
                        />
                      </div>
                      <div className="mb-3">
                        <label>{props.t("Payment Term")}</label>
                        <div className="d-flex justify-content-between">
                          {payTermLoader ? (
                            <div className="pe-2">
                              <Spinner
                                animation="border"
                                role="status"
                              ></Spinner>
                            </div>
                          ) : (
                            ""
                          )}
                          <div className="w-100 pe-2">
                            <AvField
                              type="select"
                              name="payment_term"
                              onChange={(e) => setPayTerm(e.target.value)}
                              value={payTerm}
                            >
                              <option value="" hidden>
                                {" "}
                                {props.t("Select")}
                              </option>
                              {paymentTerm &&
                                paymentTerm.map((item) => (
                                  <option value={item.id} key={item.id}>
                                    {item.name}
                                  </option>
                                ))}
                            </AvField>
                          </div>
                          <button
                            className="btn btn-primary waves-effect waves-light"
                            onClick={() => setPayTermModal(true)}
                            style={{
                              whiteSpace: "nowrap",
                            }}
                            type="button"
                          >
                            + {props.t("New")}
                          </button>
                        </div>
                      </div>
                      <div className="mb-3">
                        <AvField
                          type="checkbox"
                          label={props.t("Set as paid")}
                          name="set_as_paid"
                          onChange={() => setPaidStatus(!paidStatus)}
                        />
                      </div>
                    </Col>
                    <Col md={6}>
                      <div className="mb-3">
                        <label>{props.t("Date")}</label>
                        <DatePicker
                          selected={startDate}
                          onChange={(e) => setStartDate(e)}
                          dateFormat="dd/MM/yyyy"
                          //   minDate={new Date()}
                          autoComplete="off"
                          name="date"
                          className="is-untouched is-pristine av-valid form-control"
                        />
                      </div>
                      <div className="mb-3">
                        <AvField
                          label={props.t("Status")}
                          type="select"
                          name="status"
                          value={paidStatus ? "paid" : ""}
                        >
                          <option value="unpaid">{props.t("Unpaid")}</option>
                          <option value="draft">{props.t("Draft")}</option>
                          <option value="partially paid" disabled>
                            {props.t("Partially Paid")}
                          </option>
                          <option value="paid" disabled>
                            {props.t("Paid")}
                          </option>
                        </AvField>
                      </div>
                      <div className="mb-3">
                        <AvField
                          label={props.t("Created by")}
                          name="created_by"
                          onClick={() => setAssignedModal(true)}
                          value={assignedUserName}
                        ></AvField>
                      </div>
                      <div className="mb-3">
                        <label>{props.t("Agent")}</label>
                        <span
                          onClick={() => setAssignedModal2(true)}
                          className="form-control"
                          style={{
                            minHeight: "35px",
                          }}
                        >
                          {agentUserName}
                        </span>
                      </div>
                      <div className="mb-3">
                        <AvField
                          label={props.t("Rate")}
                          type="select"
                          name="rate"
                          value={clients.rate}
                        >
                          {allRates?.map((item, i) => (
                            <option value={item.name} key={i}>
                              {item.name}
                            </option>
                          ))}
                        </AvField>
                      </div>
                    </Col>
                  </Row>
                  <Nav tabs>
                    <NavItem>
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                          active: customActiveTab === "1",
                        })}
                        onClick={() => {
                          toggleCustom("1");
                        }}
                      >
                        <span className="d-none d-sm-block">
                          {props.t("Items")}
                        </span>
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                          active: customActiveTab === "2",
                        })}
                        onClick={() => {
                          toggleCustom("2");
                        }}
                      >
                        <span className="d-none d-sm-block">
                          {props.t("More Information")}
                        </span>
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={`${classnames({
                          active: customActiveTab === "3",
                        })}disabled`}
                        onClick={() => {
                          toggleCustom("3");
                        }}
                      >
                        <span className="d-none d-sm-block">
                          {props.t("Receipts")}
                        </span>
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={`${classnames({
                          active: customActiveTab === "4",
                        })}disabled`}
                        onClick={() => {
                          toggleCustom("4");
                        }}
                      >
                        <span className="d-none d-sm-block">
                          {props.t("Attachments")}
                        </span>
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={`${classnames({
                          active: customActiveTab === "5",
                        })}`}
                        onClick={() => {
                          toggleCustom("5");
                        }}
                      >
                        <span className="d-none d-sm-block">
                          {props.t("Signature")}
                        </span>
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={`${classnames({
                          active: customActiveTab === "6",
                        })} `}
                        onClick={() => {
                          toggleCustom("6");
                        }}
                      >
                        <span className="d-none d-sm-block">
                          {props.t("Related")}
                        </span>
                      </NavLink>
                    </NavItem>
                  </Nav>
                  <TabContent activeTab={customActiveTab} className="p-3">
                    <TabPane tabId="1" className="table-responsive">
                      <Row>
                        <Col md={3} className="p-0">
                          <div className="button-section">
                            <button
                              className="btn btn-primary"
                              type="button"
                              onClick={() => addFormFields()}
                            >
                              + &nbsp; {props.t("New Item")}
                            </button>
                          </div>
                        </Col>
                        <Col md={9}></Col>
                      </Row>
                      <Row>
                        <Col className="p-0 ">
                          <div className="table-responsive m-height250">
                            <table className="table prod_data" width="100%">
                              <thead>
                                <tr>
                                  <th></th>
                                  <th>{props.t("Reference")}</th>
                                  <th>{props.t("Name")}</th>
                                  <th>{props.t("Description")}</th>
                                  <th>{props.t("Base Price ($)")}</th>
                                  <th>{props.t("Quantity")}</th>
                                  <th>{props.t("Disc. %")}</th>
                                  <th>{props.t("Subtotal ($)")}</th>
                                  <th>{props.t("Tax")} %</th>
                                  <th>{props.t("Other Tax")}</th>
                                  <th>{props.t("Delete")}</th>
                                </tr>
                              </thead>
                              <tbody>
                                {fields.formValues &&
                                  fields.formValues.map((element, i) => (
                                    <ProductTechEstimates
                                      key={i}
                                      i={i}
                                      data={element}
                                      clientId={selectClientId}
                                      fields={fields}
                                      setFields={setFields}
                                      discountValue={discountValue}
                                      metaDiscount={metaDiscount}
                                      setMainFormDisabled={setMainFormDisabled}
                                      isEditable={"false"}
                                    />
                                  ))}
                              </tbody>
                            </table>
                          </div>
                          <Row className="row pt-3 pb-2">
                            <Col>
                              <div
                                style={{
                                  borderBottom: "1px solid #ced4da",
                                }}
                              ></div>
                            </Col>
                          </Row>
                          <Row className="">
                            <div className="d-flex justify-content-end pe-5 ms-2">
                              <div>
                                <label>{props.t("Disc. %")}: </label>
                                <input
                                  style={{
                                    width: "70px",
                                  }}
                                  className="input-style"
                                  min="0"
                                  type="number"
                                  name="meta_discount"
                                  onChange={(e) => {
                                    setDiscountValue(e.target.value),
                                      metaDiscount(e.target.value);
                                  }}
                                  value={
                                    (totalDisc / (totalBasePrice + totalDisc)) *
                                    100
                                  }
                                  disabled
                                />
                              </div>
                              <div>
                                {" "}
                                <input
                                  style={{
                                    width: "70px",
                                  }}
                                  disabled
                                  value={totalDisc.toFixed(2)}
                                  className="ms-1 input-style"
                                  name="d_value"
                                ></input>
                              </div>
                            </div>
                          </Row>
                        </Col>
                      </Row>
                      <Row>
                        <Col md={12}>
                          <div className="sub-total-div mt-5">
                            <div className="sub-total">
                              <h6>{props.t("Subtotal ($)")}:</h6>
                              <p>{totalBasePrice.toFixed(2)}</p>
                            </div>
                            {totalDisc !== 0 || totalDisc !== NaN ? (
                              <div className="sub-total">
                                <h6>{props.t("Discount (%)")}:</h6>
                                <p>{totalDisc.toFixed(2)}</p>
                              </div>
                            ) : (
                              ""
                            )}
                            <div className="sub-total">
                              <h6>{props.t("IVA ($)")}:</h6>
                              <p>{totalTax.toFixed(2)}</p>
                            </div>
                            {otherTax !== 0 ? (
                              <div className="sub-total">
                                <h6>{props.t("IR ($)")}:</h6>
                                <p>{otherTax.toFixed(2)}</p>
                              </div>
                            ) : (
                              ""
                            )}
                            <div className="sub-total2">
                              <h6>{props.t("Total ($)")}:</h6>
                              <p>
                                {subTotalShow
                                  ? subTotalShow.toFixed(2)
                                  : subTotal.toFixed(2)}
                              </p>
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </TabPane>
                    <TabPane tabId="2">
                      <Row>
                        <Col md={12}>
                          <div className="mb-3">
                            <label>{props.t("Assets")}:</label>
                            <div className="d-flex justify-content-between">
                              <div className="w-100 pe-2">
                                <Select
                                  options={optionAssets}
                                  onChange={handleAssets}
                                  isMulti
                                  name="assets"
                                // isDisabled={newDisable ? false : true}
                                />
                              </div>

                              <button
                                className="btn btn-primary waves-effect waves-light"
                                onClick={() => setassetModal(true)}
                                style={{
                                  whiteSpace: "nowrap",
                                }}
                                type="button"
                                disabled={newDisable ? false : true}
                              >
                                + {props.t("New")}
                              </button>
                            </div>
                          </div>
                        </Col>

                        <div>
                          <AvField
                            label="Filter by delivery address"
                            name="chk"
                            type="checkbox"
                          />
                        </div>

                        <Col lg={6} md={6} className="mt-3">
                          <label className="me-2">
                            {props.t("Sent date")}:
                          </label>
                          <DatePicker
                            selected={startDate2}
                            onChange={(e) => setStartDate2(e)}
                            dateFormat="dd/MM/yyyy"
                            autoComplete="off"
                            name="sent_date"
                            className="is-untouched is-pristine av-valid form-control"
                          />
                        </Col>
                        <Col lg={6} md={6} className="mt-3">
                          <label>{props.t("Delivery Options")}</label>
                          <div className="d-flex justify-content-between">
                            {deliveryOptionLoader ? (
                              <div className="pe-2">
                                <Spinner
                                  animation="border"
                                  role="status"
                                ></Spinner>
                              </div>
                            ) : (
                              ""
                            )}
                            <div className="w-100 pe-2">
                              <Select
                                options={selectDeliveryOption}
                                onChange={handleSelectDeliveryOption}
                                name="delivery_option"
                                defaultValue={defaultDeliveyOption}
                              />
                            </div>
                            <button
                              className="btn btn-primary waves-effect waves-light"
                              onClick={() => setDeliveryOptionModal(true)}
                              style={{
                                whiteSpace: "nowrap",
                              }}
                              type="button"
                            >
                              + {props.t("New")}
                            </button>
                          </div>
                        </Col>
                        <Col lg={6} md={6} className="mt-3">
                          {newDisable ? (
                            <AvField
                              type="text"
                              name="inv_address"
                              label={props.t("Inv. Address")}
                              value={clientData}
                              onClick={() => setIsOpenAddress(true)}
                            />
                          ) : (
                            <AvField
                              type="text"
                              name="inv_address"
                              label={props.t("Inv. Address")}
                              disabled
                            />
                          )}
                        </Col>
                        <Col lg={6} md={6} className="mt-3">
                          {newDisable ? (
                            <AvField
                              type="text"
                              name="del_address"
                              //   name="delivery_address"
                              label={props.t("Del. Address")}
                              value={workData}
                              //   value={clientData}
                              onClick={() => setIsWorkAddress(true)}
                            />
                          ) : (
                            <AvField
                              type="text"
                              name="del_address"
                              //   name="delivery_address"
                              label={props.t("Del. Address")}
                              disabled
                            />
                          )}
                        </Col>
                        <Col lg={6} md={6} className="mt-3">
                          <label className="me-2">
                            {props.t("Email Sent date")}:
                          </label>
                          <DatePicker
                            selected={emailDate}
                            onChange={(e) => setEmailDate(e)}
                            dateFormat="dd/MM/yyyy"
                            // minDate={new Date()}
                            name="email_sent_date"
                            className="is-untouched is-pristine av-valid form-control"
                          />
                        </Col>
                        <Col lg={6} md={6} className="mt-3"></Col>
                        <Col lg={6} md={6} className="mt-3">
                          <AvField
                            type="select"
                            name="currency"
                            label={props.t("Currency")}
                            value="usd"
                          >
                            <option value="inr">INR</option>
                            <option value="usd">USD</option>
                            <option value="gbp">GBP</option>
                          </AvField>
                        </Col>
                        <Col lg={6} md={6} className="mt-3">
                          <AvField
                            type="text"
                            name="currency_rate"
                            label={props.t("Currency Rate")}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col md={12}>
                          <div className="mb-3 mt-3">
                            <AvField
                              label={props.t("Comments")}
                              type="textarea"
                              name="comments"
                            />
                          </div>
                        </Col>
                        <Col md={12}>
                          <div className="mb-3">
                            <AvField
                              label={props.t("Private Comments")}
                              type="textarea"
                              name="private_comments"
                            />
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col md={12} className="mt-3">
                          <AvField
                            label={props.t("Addendum")}
                            type="textarea"
                            name="addendum"
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col className="mt-3">
                          <div>
                            <AvField
                              label={props.t("Created from")}
                              type="text"
                              name="created_from"
                              value={props.t("Web")}
                              disabled
                            />
                          </div>
                        </Col>
                      </Row>
                    </TabPane>
                    <TabPane tabId="5">
                      <Row>
                        <Col lg="6">
                          <div className="mb-3">
                            <AvField
                              label={props.t("Name")}
                              type="text"
                              name="name"
                            />
                          </div>
                          <div className="mb-3 required">
                            <AvField
                              label={props.t("Ced/Ruc")}
                              type="number"
                              value={clients.tin}
                              onKeyPress={(e) => {
                                if (e.target.value.length == 13)
                                  e.preventDefault();
                              }}
                              name="tin"
                              required="required"
                            />
                          </div>
                          <div className="company_img img-flex">
                            <label>{props.t("Main image")}</label>
                            <input
                              className="hidden d-none"
                              onChange={(e) => setComImg(e)}
                              type="file"
                              id="comImg"
                            />
                            <label className="company_img-box" htmlFor="comImg">
                              <img src={imgPreview} />
                            </label>
                          </div>
                        </Col>
                      </Row>
                    </TabPane>
                  </TabContent>
                </CardBody>
              </Card>
            </AvForm>
          </div>
          {assignedModal ? (
            <AssignUserList
              setAssignedModal={setAssignedModal}
              getAssignedUser={getAssignedUser}
              data={assignUsers}
            />
          ) : (
            ""
          )}

          {assignedModal2 ? (
            <AssignUserList
              setAssignedModal={setAssignedModal2}
              getAssignedUser={getAgentUser}
              data={assignUsers}
            />
          ) : (
            ""
          )}
        </Container>
      </div>

      <Modal size="lg" isOpen={assetModal} centered={true}>
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myLargeModalLabel">
            New Assest
          </h5>
          <button
            type="button"
            className="cross__btn btn btn-primary waves-effect waves-light"
            onClick={() => setassetModal(false)}
            aria-hidden="true"
          >
            &times;
          </button>
        </div>
        <div className="modal-body">
          <AssetsNew
            clientData={clients}
            workData={clients}
            modalData={assetModal}
            setassetModal={setassetModal}
            AssetsData={AssetsData}
            clientNameData={clientNameData}
          />
        </div>
      </Modal>

      {isOpenModalNew ? (
        <NewPayment
          setIsOpenModal={setIsOpenModalNew}
          NewpaymentShow={NewpaymentShow}
          setPaymentOption={setPaymentOption}
        />
      ) : (
        ""
      )}
      {payTermModal ? (
        <PayTermModal
          setPayTerm={setPayTerm}
          getAllPayment={getAllPayment}
          setPayTermModal={setPayTermModal}
        />
      ) : (
        ""
      )}
      {selectClientModal ? (
        <ClientList
          setSelectClientModal={setSelectClientModal}
          setSelectClientId={setSelectClientId}
          clients={allClients}
          clientDetail={clientDetail}
          getClients={getClients}
        />
      ) : (
        ""
      )}
      {selectClientModal2 ? (
        <ClientList
          setSelectClientModal={setSelectClientModal2}
          setSelectClientId={setSelectClientId2}
          clients={allClients}
          clientDetail={clientDetail2}
        />
      ) : (
        ""
      )}
    </React.Fragment>
  );
};
export default withTranslation()(InvoicingService);
InvoicingService.propTypes = {
  t: PropTypes.any,
};
