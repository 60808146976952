import React, { useState, useEffect } from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import { Link } from "react-router-dom";
import { Button } from "reactstrap";
const axios = require("axios").default;
import { getCurrentUser, getPermission } from "../../helpers/Utils";
import uploadImg from "../../assets/images/def-img.svg";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
let selectedRoleId = [];

function SingleList(props) {
    const [product, setProduct] = useState(props.data);
    const [confirm_alert, setconfirm_alert] = useState(false);
    const [success_dlg, setsuccess_dlg] = useState(false);
    const [dynamic_title, setdynamic_title] = useState("");
    const [dynamic_description, setdynamic_description] = useState("");

    const deleteProduct = (Id) => {
        axios
            .delete(
                `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                    "companyId"
                )}/products/${Id}`,
                {
                    headers: {
                        "content-type": "application/json",
                        Authorization: `Bearer ${getCurrentUser().token}`,
                    },
                }
            )
            .then(function (res) {
                setProduct(null);
                setdynamic_description(res.data.message);
            });
    };
    useEffect(() => {
        selectedRoleId = [];
    }, []);

    const getSelectedId = (e, check) => {
        if (check.target.checked) {
            selectedRoleId.push(e);
            const uniqueID = selectedRoleId.filter((val, id, array) => {
                return array.indexOf(val) == id;
            });
            selectedRoleId = uniqueID;
            props.setSelectedIds(selectedRoleId);
        } else {
            selectedRoleId = props.selectedIds;
            var array = selectedRoleId;
            var index = array.indexOf(e);
            if (index !== -1) {
                array.splice(index, 1);
            }
            props.setSelectedIds(selectedRoleId);
        }
    };

    if (product === null) {
        return "";
    } else
        return (
            <>
                <tr>
                    <td>
                        <input
                            onChange={(e) => getSelectedId(product.id, e)}
                            name="chk"
                            value={product.id}
                            className="p-0 d-inline-block"
                            type="checkbox"
                        />
                    </td>
                    <td>{props.i + 1}</td>
                    <td role="button" className="py-1">
                        <Link to={`/product/${product.id}`}>
                            <img
                                height="40"
                                width="40"
                                style={{ objectFit: "cover" }}
                                src={product.image ? product.image : uploadImg}
                            />
                        </Link>
                    </td>
                    <td role="button">
                        <Link to={`/product/${product.id}`}>
                            {product.reference}
                            {product.reference_number}
                        </Link>
                    </td>
                    <td role="button">
                        <Link to={`/product/${product.id}`}>{product.name}</Link>
                    </td>
                    {/* <td role="button"> */}
                    {/* 	<Link to={`/product/${product.id}`}> */}
                    {/* 		{product.stock} */}
                    {/* 	</Link> */}
                    {/* </td> */}
                    {/* <td role="button"> */}
                    {/* 	<Link to={`/product/${product.id}`}> */}
                    {/* 		{product.virtual_stock} */}
                    {/* 	</Link> */}
                    {/* </td> */}
                    <td role="button">
                        <Link to={`/product/${product.id}`}>{product.price}</Link>
                    </td>
                    <td role="button">
                        <Link to={`/product/${product.id}`}>
                            {product.is_promotional == "1" ? (
                                <i className="fa fa-check"></i>
                            ) : (
                                ""
                            )}
                        </Link>
                    </td>
                    <td role="button">
                        <Link to={`/product/${product.id}`}>{product.manage_stock == 0 ? '-' : product?.stock}</Link>
                    </td>
                    {getPermission().products.products.delete.is_checked === 1 ? (
                        <td className="text-end">
                            <Button
                                color="danger"
                                onClick={() => {
                                    setconfirm_alert(true);
                                }}
                            >
                                {props.t("Delete")}
                            </Button>
                        </td>
                    ) : (
                        ""
                    )}
                </tr>
                {confirm_alert ? (
                    <SweetAlert
                        title={props.t("Are you sure?")}
                        cancelBtnText={props.t("Cancel")}
                        confirmBtnText={props.t("Yes, delete it!")}
                        warning
                        showCancel
                        confirmBtnBsStyle="success"
                        cancelBtnBsStyle="danger"
                        onConfirm={() => {
                            deleteProduct(product.id);
                            setconfirm_alert(false);
                            setsuccess_dlg(true);
                            setdynamic_title("Deleted");
                            setdynamic_description("The product has been deleted.");
                        }}
                        onCancel={() => setconfirm_alert(false)}
                    ></SweetAlert>
                ) : null}

                {success_dlg ? (
                    <SweetAlert
                        success
                        title={props.t(dynamic_title)}
                        onConfirm={() => {
                            setsuccess_dlg(false);
                        }}
                    >
                        {props.t(dynamic_description)}
                    </SweetAlert>
                ) : null}
            </>
        );
}
export default withTranslation()(SingleList);
SingleList.propTypes = {
    t: PropTypes.any,
};
