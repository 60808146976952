import React, { useState, useEffect } from "react";
import MetaTags from "react-meta-tags";
import {
  AvForm,
  AvField,
  AvRadioGroup,
  AvRadio,
} from "availity-reactstrap-validation";
import {
  Nav,
  Alert,
  NavItem,
  NavLink,
  Card,
  CardBody,
  Col,
  Container,
  Row,
  TabContent,
  TabPane,
  Spinner,
} from "reactstrap";
import Select from "react-select";
import classnames from "classnames";
import countryList from "react-select-country-list";
import { getCurrentUser } from "../../helpers/Utils";
const axios = require("axios").default;
import ClientCategory from "../Category/client/client-category";
import NewPayment from "../../components/PaymentOption/payment_option";
import "react-phone-input-2/lib/style.css";
import PhoneInput from "react-phone-input-2";
import Loader from "../../components/Loader";
import { useHistory } from "react-router-dom";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
let cc = require("currency-codes");

const CreateClient = (props) => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [customActiveTab, setCustomActiveTab] = useState("general");
  const [referenceType, setReferenceType] = useState("");
  const [clients, setClients] = useState([]);
  const [modal, setModal] = useState(false);
  const [selectedCate, setSelectedCate] = useState("");
  const [allCategories, setAllCategories] = useState([]);
  const [allRates, setAllRates] = useState([]);
  const [swift, setSwift] = useState(false);
  const [phone, setPhone] = useState("");
  const [phone2, setPhone2] = useState("");
  const [paymentLoader, setPaymentLoader] = useState(false);
  const [paymentData, setPaymentData] = useState("");
  const [isOpenModalNew, setIsOpenModalNew] = useState(false);
  const [paymentTerm, setPaymentTerm] = useState("");
  const [allRef, setAllRef] = useState([]);
  const [defaultRef, setDefaultRef] = useState("");
  const [loader, setLoader] = useState(<Loader />);
  const [company, setCompany] = useState(null);

  const toggleCustom = (tab) => {
    if (customActiveTab !== tab) {
      setCustomActiveTab(tab);
    }
    if (tab == "commercial") {
      getAllRates();
    }
  };

  const getSingleCompany = () => {
    setLoader(true);
    axios
      .get(
        `${process.env.REACT_APP_APIKEY}/companies/${localStorage.getItem(
          "companyId"
        )}?company_id=${localStorage.getItem("companyId")}`,
        {
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${getCurrentUser().token}`,
          },
        }
      )
      .then(function (res) {
        setLoader(false);
        setCompany(res.data.company);
      });
  };

  useEffect(() => {
    getSingleCompany();
  }, []);

  const getAllRates = () => {
    axios
      .get(
        `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
          "companyId"
        )}/rates`,
        {
          headers: {
            Authorization: `Bearer ${getCurrentUser().token}`,
          },
        }
      )
      .then(function (res) {
        if (res.data.status) {
          setAllRates(res.data.rates);
          setError("");
        } else {
          setError(res.data.message);
        }
      });
  };

  const newUserSubmit = (e, value) => {
    setLoading(true);
    const formData = new FormData();
    formData.append("reference", value.reference);
    formData.append("reference_number", value.reference_number);
    formData.append("legal_name", value.legal_name);
    formData.append("tin", value.tin);
    formData.append("phone_1", value.phone_1);
    formData.append("address", value.address);
    formData.append("state", value.state);
    formData.append("name", value.name);
    formData.append("email", value.email);
    formData.append("city", value.city);
    formData.append("zip_code", value.zip_code);
    formData.append("fax", value.fax);
    formData.append("website", value.website);
    formData.append("comments", value.comments);
    formData.append("country", e.target.country.value);
    formData.append("popup_notice", value.popup_notice);
    formData.append("created_from", value.created_from);
    formData.append("phone_2", phone2);
    formData.append("payment_date", value.payment_date);
    formData.append("payment_option_id", value.payment_option_id);
    formData.append("discount", value.discount);
    formData.append("rate", value.rate);
    formData.append("currency", value.currency);
    // if (e.target.subject_to_vat.value == "on") {
    //   subject_to_vat = 1;
    // }
    formData.append("subject_to_vat", "1");
    // if (e.target.subject_to_income_tax.value == "on") {
    //   subject_to_income_tax = 1;
    // }
    formData.append("subject_to_income_tax", "1");
    formData.append("maximum_risk", value.maximum_risk);
    if (value.payment_terms_id) {
      formData.append("payment_terms_id", value.payment_terms_id);
    }
    if (value.payment_adjustment) {
      formData.append("payment_adjustment", value.payment_adjustment);
    }
    formData.append("agent", value.agent);
    formData.append("invoice_to", value.invoice_to);
    formData.append("bank_account_format", value.bank_account_format);
    formData.append("bank_account_account", value.bank_account_account);
    formData.append(
      "bank_account_account_holder",
      value.bank_account_account_holder
    );
    // formData.append("bank_account_bic", value.bank_account_bic);
    if (value.swift_aba) {
      formData.append("swift_aba", value.swift_aba);
    }
    if (value.ced_ruc) {
      formData.append("ced_ruc", value.ced_ruc);
    }
    formData.append("bank_account_name", value.bank_account_name);
    formData.append("bank_account_description", value.bank_account_description);
    if (selectedCate) {
      formData.append("client_category", selectedCate);
    }
    axios
      .post(
        `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
          "companyId"
        )}/clients`,
        formData,
        {
          headers: {
            "Content-type": "multipart/form-data",
            Authorization: `Bearer ${getCurrentUser().token}`,
          },
        }
      )
      .then(function (res) {
        setLoading(false);
        if (res.data.status) {
          setError("");
          setSuccess(res.data.message);
          history.push(`/client/${res.data.client.id}`);
        } else {
          setError(res.data.message);
          setSuccess("");
        }
        setTimeout(() => {
          setError("");
          setSuccess("");
        }, 4000);
      });
  };

  //   const getClients = () => {
  //     axios
  //       .get(
  //         `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
  //           "companyId"
  //         )}/clients`,
  //         {
  //           headers: {
  //             Authorization: `Bearer ${getCurrentUser().token}`,
  //           },
  //         }
  //       )
  //       .then(function (res) {
  //         setClients(res.data.clients);
  //       });
  //   };

  const getType = (e) => {
    setReferenceType(e.target.value);
  };

  let optionCategory = [];
  if (allCategories !== undefined) {
    {
      allCategories &&
        allCategories.map((category) => {
          optionCategory.push({
            value: category.id,
            label: category.name,
          });
        });
    }
  }

  function handleSelectCategory(selectedcate) {
    setSelectedCate(selectedcate.value);
  }

  const CategoriesClient = () => {
    axios
      .get(
        `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
          "companyId"
        )}/client-categories?type=client`,
        {
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${getCurrentUser().token}`,
          },
        }
      )
      .then(function (res) {
        setAllCategories(res.data.client_categories);
      });
  };
  const newpaymentShow = () => {
    setPaymentLoader(true);
    axios
      .get(
        `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
          "companyId"
        )}/payment-options`,
        {
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${getCurrentUser().token}`,
          },
        }
      )
      .then(function (res) {
        setPaymentData(res.data.PaymentOptions);
        setPaymentLoader(false);
      });
  };
  const getCountry = (e) => {
    setPhone(e);
  };
  const getCountry2 = (e) => {
    setPhone2(e);
  };
  const getAllPayment = () => {
    setPaymentData([]);
    axios
      .get(
        `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
          "companyId"
        )}/payment-terms`,
        {
          headers: {
            Authorization: `Bearer ${getCurrentUser().token}`,
          },
        }
      )
      .then(function (res) {
        setLoader("");
        if (res.data.status) {
          setError("");
          setPaymentTerm(res.data.payment_terms);
        }
      });
  };
  const getReferences = () => {
    setAllRef([]);
    axios
      .get(
        `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
          "companyId"
        )}/references?type=Client`,
        {
          headers: {
            Authorization: `Bearer ${getCurrentUser().token}`,
          },
        }
      )
      .then(function (res) {
        // setLoader("");
        if (res.data.status) {
          setError("");
          setAllRef(res.data.reference);
          res.data.reference?.map((item) => {
            if (item.by_default === "1") {
              setDefaultRef(item.prefix);
            }
          });
        } else {
          //   setError(res.data.message);
        }
      });
  };

  useEffect(() => {
    // getClients();
    getAllPayment();
    CategoriesClient();
    newpaymentShow();
    getReferences();
  }, []);

  return (
    <React.Fragment>
      {isOpenModalNew ? (
        <NewPayment
          setIsOpenModal={setIsOpenModalNew}
          NewpaymentShow={newpaymentShow}
        />
      ) : (
        ""
      )}
      <div className="page-content">
        <MetaTags>
          <title>Create new client</title>
        </MetaTags>
        {modal ? (
          <ClientCategory
            setIsOpenModal={setModal}
            CategoriesClient={CategoriesClient}
          />
        ) : (
          ""
        )}
        <Container fluid>
          <div className="create_company">
            <AvForm
              className="form-horizontal"
              onValidSubmit={(e, v) => {
                newUserSubmit(e, v);
              }}
            >
              <div className="page-title-box">
                <Row className="align-items-center">
                  <Col md={6}>
                    <h6 className="page-title">{props.t("New Client")}</h6>
                  </Col>
                  <Col md={6}>
                    <div className="float-end d-md-block">
                      {loading ? (
                        <button
                          className="btn btn-primary  disabled"
                          type="button"
                          disabled
                        >
                          {props.t("Loading")}
                        </button>
                      ) : (
                        <button className="btn btn-primary " type="submit">
                          {props.t("Save")}
                        </button>
                      )}
                    </div>
                  </Col>
                </Row>
              </div>
              {success ? (
                <Alert color="success">{props.t(success)}</Alert>
              ) : null}

              {error ? <Alert color="danger">{props.t(error)}</Alert> : null}

              <Card>
                <CardBody>
                  <Row>
                    <Col lg={6}>
                      {/* <div className="required"> */}
                      <label>{props.t("Reference")}</label>
                      {/* </div> */}
                      <div className="d-flex">
                        <AvField
                          style={{
                            width: "100px",
                          }}
                          type="select"
                          name="reference"
                          value={defaultRef}
                          onChange={(e) => getType(e)}
                        >
                          {allRef &&
                            allRef.map((item, i) => (
                              <option value={item.prefix} key={i}>
                                {item.prefix}
                              </option>
                            ))}
                          <option value="manual">MANUAL</option>
                        </AvField>
                        <div className="w-100 ps-3">
                          <AvField
                            className="mb-3 w-100"
                            type="number"
                            name="reference_number"
                            placeholder={
                              referenceType !== "manual" ? "Automatic" : ""
                            }
                          />
                        </div>
                      </div>
                      <div className="mb-3 required">
                        <AvField
                          label={props.t("Legal Name")}
                          type="text"
                          name="legal_name"
                          required="required"
                        />
                      </div>
                      <div className="mb-3 required">
                        <AvField
                          label={props.t("Ced/Ruc")}
                          type="number"
                          onKeyPress={(e) => {
                            if (e.target.value.length == 13) e.preventDefault();
                          }}
                          name="tin"
                          required="required"
                        />
                      </div>
                    </Col>
                    <Col lg={6}>
                      <AvField
                        className="mb-3"
                        label={props.t("Name")}
                        type="text"
                        name="name"
                      />
                      <div className="mb-3">
                        <AvField
                          label={props.t("Email")}
                          type="email"
                          name="email"
                        />
                      </div>
                      {/* <div className="mb-3">
                        <label>{props.t("Phone 1")}</label>
                        <PhoneInput
                          placeholder={props.t("Enter phone number")}
                          value={phone}
                          onChange={(e) => getCountry(e)}
                          name="phone_1"
                          inputStyle={{ width: "100%" }}
                          //   rules={{ required: true }}
                        />
                      </div> */}
                      <AvField
                        className="mb-3"
                        placeholder={props.t("Enter phone number")}
                        label={props.t("Phone 1")}
                        type="number"
                        name="phone_1"
                        maxLength={10}
                        validate={{
                          maxLength: {
                            value: 10,
                            errorMessage: props.t(
                              "The number cannot be more than 10 digits."
                            ),
                          },
                        }}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={6}>
                      <AvField
                        className="mb-3"
                        label={props.t("Address")}
                        type="text"
                        name="address"
                        value={company?.city}
                      />
                      {loader ? (
                        <Spinner />
                      ) : (
                        <AvField
                          className="mb-3"
                          label={props.t("State/Province")}
                          type="text"
                          name="state"
                          value={company?.state}
                        />
                      )}
                      <div className="mb-3">
                        <AvField
                          type="select"
                          name="country"
                          label={props.t("Country")}
                          defaultValue="Ecuador"
                        >
                          <option value="">Select</option>
                          {countryList().data.map((item, i) => (
                            <option value={item.label} key={i}>
                              {item.label}
                            </option>
                          ))}
                        </AvField>
                      </div>
                    </Col>
                    <Col lg={6}>
                      <AvField
                        className="mb-3"
                        placeholder={props.t("Enter phone number")}
                        label={props.t("Phone 2")}
                        type="number"
                        name="phone_2"
                        maxLength={10}
                        validate={{
                          maxLength: {
                            value: 10,
                            errorMessage: props.t(
                              "The number cannot be more than 10 digits."
                            ),
                          },
                        }}
                      />
                      {loader ? (
                        <Spinner />
                      ) : (
                        <AvField
                          className="mb-3"
                          label={props.t("City/town")}
                          type="text"
                          name="city"
                          value={company?.city}
                        />
                      )}
                      <AvField
                        className="mb-3"
                        label={props.t("Zip code")}
                        type="text"
                        name="zip_code"
                      />
                    </Col>
                  </Row>
                  <Nav tabs className="mt-5">
                    <NavItem>
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                          active: customActiveTab === "general",
                        })}
                        onClick={() => {
                          toggleCustom("general");
                        }}
                      >
                        <span className="d-none d-sm-block">
                          {props.t("General")}
                        </span>
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                          active: customActiveTab === "commercial",
                        })}
                        onClick={() => {
                          toggleCustom("commercial");
                        }}
                      >
                        <span className="d-none d-sm-block">
                          {props.t("Commercial")}
                        </span>
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                          active: customActiveTab === "special_prices",
                        })}
                        onClick={() => {
                          toggleCustom("special_prices");
                        }}
                        disabled
                      >
                        <span className="d-none d-sm-block">
                          {props.t("Special Prices")}
                        </span>
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                          active: customActiveTab === "bank_account",
                        })}
                        onClick={() => {
                          toggleCustom("bank_account");
                        }}
                      >
                        <span className="d-none d-sm-block">
                          {props.t("Bank Account")}
                        </span>
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                          active: customActiveTab === "contacts",
                        })}
                        onClick={() => {
                          toggleCustom("contacts");
                        }}
                        disabled
                      >
                        <span className="d-none d-sm-block">
                          {props.t("Contacts")}
                        </span>
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                          active: customActiveTab === "other_addresses",
                        })}
                        onClick={() => {
                          toggleCustom("other_addresses");
                        }}
                        disabled
                      >
                        <span className="d-none d-sm-block">
                          {props.t("Other Addresses")}
                        </span>
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                          active: customActiveTab === "assets",
                        })}
                        onClick={() => {
                          toggleCustom("assets");
                        }}
                        disabled
                      >
                        <span className="d-none d-sm-block">
                          {props.t("Assets")}
                        </span>
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                          active: customActiveTab === "attachments",
                        })}
                        onClick={() => {
                          toggleCustom("attachments");
                        }}
                        disabled
                      >
                        <span className="d-none d-sm-block">
                          {props.t("Attachments")}
                        </span>
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                          active: customActiveTab === "balance",
                        })}
                        onClick={() => {
                          toggleCustom("balance");
                        }}
                        disabled
                      >
                        <span className="d-none d-sm-block">
                          {props.t("Balance")}
                        </span>
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                          active: customActiveTab === "related",
                        })}
                        onClick={() => {
                          toggleCustom("related");
                        }}
                        disabled
                      >
                        <span className="d-none d-sm-block">
                          {props.t("Related")}
                        </span>
                      </NavLink>
                    </NavItem>
                  </Nav>
                  <TabContent activeTab={customActiveTab} className="p-3">
                    <TabPane tabId="general">
                      <Row>
                        <Col lg="6">
                          <AvField
                            className="mb-3"
                            label={props.t("Fax")}
                            type="text"
                            name="fax"
                          />
                          <AvField
                            className="mb-3"
                            label={props.t("Website")}
                            type="text"
                            name="website"
                            placeholder="web format"
                          />
                        </Col>
                        <Col lg="6">
                          <label>{props.t("Client Category")}</label>
                          <div className="d-flex mb-3">
                            <div className="w-100 me-2">
                              <Select
                                options={optionCategory}
                                onChange={handleSelectCategory}
                                name="client_category"
                              />
                            </div>
                            <button
                              type="button"
                              onClick={() => {
                                setModal(true);
                              }}
                              className="btn btn-primary"
                              data-toggle="modal"
                              data-target=".bs-example-modal-lg"
                            >
                              {props.t("New")}
                            </button>
                          </div>
                          <AvField
                            className="mb-3"
                            label={props.t("Popup notice")}
                            type="text"
                            name="popup_notice"
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col lg="12">
                          <AvField
                            className="mb-3"
                            label={props.t("Comments")}
                            type="textarea"
                            name="comments"
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col lg="12">
                          <AvField
                            className="mb-3"
                            label={props.t("Created from")}
                            type="text"
                            name="created_from"
                            value={props.t("WEB")}
                            readOnly
                          />
                        </Col>
                      </Row>
                    </TabPane>
                    <TabPane tabId="commercial">
                      <Row>
                        <Col lg="6">
                          <div className="mb-3">
                            <label>{props.t("Payment Option")}</label>
                            <div className="d-flex justify-content-between">
                              {paymentLoader ? (
                                <div className="pe-2">
                                  <Spinner
                                    animation="border"
                                    role="status"
                                  ></Spinner>
                                </div>
                              ) : (
                                ""
                              )}
                              <div className="w-100 pe-2">
                                <AvField type="select" name="payment_option_id">
                                  <option value="" hidden>
                                    {props.t("Select")}
                                  </option>
                                  {paymentData &&
                                    paymentData.map((item, i) => (
                                      <option value={item.id} key={i}>
                                        {item.name}
                                      </option>
                                    ))}
                                </AvField>
                              </div>
                              <button
                                className="btn btn-primary waves-effect waves-light"
                                onClick={() => setIsOpenModalNew(true)}
                                style={{
                                  whiteSpace: "nowrap",
                                }}
                                type="button"
                              >
                                + {props.t("New")}
                              </button>
                            </div>
                          </div>

                          <div className="mb-3">
                            <AvField
                              type="select"
                              name="payment_date"
                              label={props.t("Payment Date")}
                            >
                              <option value="">
                                {props.t("Select a Date")}...
                              </option>
                              <option value="1">1</option>
                              <option value="2">2</option>
                              <option value="3">3</option>
                              <option value="4">4</option>
                              <option value="5">5</option>
                              <option value="6">6</option>
                              <option value="7">7</option>
                              <option value="8">8</option>
                              <option value="9">9</option>
                              <option value="10">10</option>
                              <option value="11">11</option>
                              <option value="12">12</option>
                              <option value="13">13</option>
                              <option value="14">14</option>
                              <option value="15">15</option>
                              <option value="16">16</option>
                              <option value="17">17</option>
                              <option value="18">18</option>
                              <option value="19">19</option>
                              <option value="20">20</option>
                              <option value="21">21</option>
                              <option value="22">22</option>
                              <option value="23">23</option>
                              <option value="24">24</option>
                              <option value="25">25</option>
                              <option value="26">26</option>
                              <option value="27">27</option>
                              <option value="28">28</option>
                              <option value="29">29</option>
                              <option value="30">30</option>
                              <option value="31">31</option>
                            </AvField>
                          </div>
                          <div className="mb-3">
                            <AvField
                              className="mb-3"
                              label={props.t("Discount (%)")}
                              type="text"
                              name="discount"
                              value="0.00"
                            />
                          </div>
                          <div className="mb-3">
                            <AvField
                              type="select"
                              name="rate"
                              label={props.t("Rate")}
                            >
                              {allRates?.map((item, i) => (
                                <option value={item.name} key={i}>
                                  {item.name}
                                </option>
                              ))}
                            </AvField>
                          </div>
                          <div className="mb-3">
                            <AvField
                              type="select"
                              name="currency"
                              label={props.t("Currency")}
                              value="USD"
                            >
                              {cc.data.map((currency, i) => (
                                <option value={currency.code} key={i}>
                                  {currency.code} - {currency.currency}
                                </option>
                              ))}
                            </AvField>
                          </div>
                          {/* <div className="mb-3">
                            <label>Subject to VAT:</label>
                              className="ms-2"
                              name="subject_to_vat"
                            />
                          </div> */}
                          <div className="mb-3">
                            <AvField
                              className="mb-3"
                              label={props.t("Maximum Risk($)")}
                              type="number"
                              name="maximum_risk"
                              value="0.00"
                            />
                          </div>
                        </Col>
                        <Col lg="6">
                          <div className="mb-3">
                            <AvField
                              label={props.t("Payment Term")}
                              type="select"
                              name="payment_terms_id"
                            >
                              <option value="">Select</option>
                              {/* <option value="0">Immediate Payment</option> */}
                              {paymentTerm &&
                                paymentTerm.map((item, i) => (
                                  <option value={item.id} key={i}>
                                    {item.name}
                                  </option>
                                ))}
                            </AvField>
                          </div>
                          {/* <div className="mb-3">
                            <AvField
                              type="select"
                              name="payment_terms"
                              label="Payment Terms"
                            >
                              <option value="">Select a Payment Term...</option>
                              <option value="immediate_payment">
                                Immediate Payment
                              </option>
                              <option value="days_payment">
                                30-60-90 Days Payment
                              </option>
                            </AvField>
                          </div> */}
                          <div className="mb-3">
                            <AvField
                              type="select"
                              name="payment_adjustment"
                              label={props.t("Payment Adjustment")}
                            >
                              <option value="" hidden>
                                {props.t("Select")}
                              </option>
                              <option value="unspecified">
                                {props.t("Unspecified")}
                              </option>
                              <option value="previous_than">
                                {props.t("Previous than")}
                              </option>
                              <option value="later_than">
                                {props.t("Later than")}
                              </option>
                              <option value="closest_to">
                                {props.t("Closest to")}
                              </option>
                            </AvField>
                          </div>
                          <AvField
                            className="mb-3"
                            label={props.t("Agent")}
                            type="text"
                            name="agent"
                            value={getCurrentUser().email}
                          />
                          <div className="mb-3">
                            <AvField
                              type="select"
                              name="invoice_to"
                              label={props.t("Invoice to")}
                              value="USD"
                            >
                              {clients &&
                                clients.map((client, i) => (
                                  <option value={client.id} key={i}>
                                    {client.name}
                                  </option>
                                ))}
                            </AvField>
                          </div>
                          {/* <div className="mb-3">
                            <label>Subject to Income Tax:</label>
                              className="ms-2"
                              name="subject_to_income_tax"
                            />
                          </div> */}
                        </Col>
                      </Row>
                    </TabPane>
                    <TabPane tabId="bank_account">
                      <Row>
                        <Col lg="12">
                          <div className="mb-3">
                            <label>{props.t("Account type")}</label>
                            <AvRadioGroup
                              name="bank_account_format"
                              className="radiobutton"
                            >
                              <AvRadio
                                label={props.t("AHORROS")}
                                value="ahorros"
                                onClick={() => setSwift(false)}
                              />
                              <AvRadio
                                label={props.t("CORRIENTE")}
                                value="corriente"
                                onClick={() => setSwift(false)}
                              />
                              {/* <AvRadio label="Swift/ABA" value="swift/aba" onClick={() => setSwift(true)}/> */}
                            </AvRadioGroup>
                          </div>
                          <div className="mb-3">
                            <AvField
                              label={props.t("Bank Name")}
                              type="text"
                              name="bank_account_name"
                            />
                          </div>
                          <div className="mb-3">
                            <AvField
                              label={props.t("Account number")}
                              type="text"
                              name="bank_account_account"
                            />
                          </div>
                          <div className="mb-3">
                            <AvField
                              label={props.t("Account Holder Name")}
                              type="text"
                              name="bank_account_account_holder"
                            />
                          </div>
                          {/* <div className="mb-3">
                                                        <AvField
                                                        label="BIC/SWIFT"
                                                        type="text"
                                                        name="bank_account_bic"
                                                        />
                                                    </div> */}
                          <div className="mb-3">
                            <AvField
                              label={props.t("ABA/SWIFT")}
                              type="text"
                              name="swift_aba"
                            />
                          </div>
                          <div className="mb-3">
                            <AvField
                              label={props.t("CED/RUC")}
                              type="number"
                              onKeyPress={(e) => {
                                if (e.target.value.length == 13)
                                  e.preventDefault();
                              }}
                              name="ced_ruc"
                            />
                          </div>
                          {/* <div className="mb-3">
                                                        <AvField
                                                        label="Description"
                                                        type="textarea"
                                                        name="bank_account_description"
                                                        />
                                                    </div> */}
                        </Col>
                      </Row>
                    </TabPane>
                  </TabContent>
                </CardBody>
              </Card>
            </AvForm>
          </div>
        </Container>
      </div>
    </React.Fragment>
  );
};
export default withTranslation()(CreateClient);
CreateClient.propTypes = {
  t: PropTypes.any,
};
